import CryptoJS from "crypto-js";
//随机生成指定数量的32进制key
export default {
    generatekey(num) {
        let library =
            "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
        let key = "";
        for (let i = 0; i < num; i++) {
            let randomPoz = Math.floor(Math.random() * library.length);
            key += library.substring(randomPoz, randomPoz + 1);
        }
        return key;
    },
    //加密
    encrypt(word, keyStr) {
        keyStr = keyStr ? keyStr : "94tlt9ZFzp1hBjkY8NJdo3jUMGXib56n";
        let key = CryptoJS.enc.Utf8.parse(keyStr);
        let srcs = CryptoJS.enc.Utf8.parse(word);
        var iv = CryptoJS.enc.Utf8.parse(keyStr.substring(0, 16));
        let encrypted = CryptoJS.AES.encrypt(srcs, key, {
            iv: iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7
        });
        return CryptoJS.enc.Base64.stringify(encrypted.ciphertext);
    },
    decrypt(word, keyStr){
        keyStr = keyStr ? keyStr : "94tlt9ZFzp1hBjkY8NJdo3jUMGXib56n";
        let key = CryptoJS.enc.Utf8.parse(keyStr);
        let base64 = CryptoJS.enc.Base64.parse(word);
        let src = CryptoJS.enc.Base64.stringify(base64);

        // 解密模式为CBC，补码方式为PKCS5Padding（也就是PKCS7）
        let decrypt = CryptoJS.AES.decrypt(src, key, {
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7
        });

        let decryptedStr = decrypt.toString(CryptoJS.enc.Utf8);
        return decryptedStr.toString();
    }
};
