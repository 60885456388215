import axios from 'axios'
import {ElMessage as Message} from 'element-plus'
/*import { useStore } from 'vuex'*/

// export const base_url = process.env.NODE_ENV === 'development' ? process.env.VUE_APP_BASE_API : process.env.VUE_APP_BASE_URL
const http = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  withCredentials: false,
  timeout: 500000
})
/*const store = useStore()
const setShowAjaxLoading = (value) => store.dispatch('setShowAjaxLoading',value)*/

http.interceptors.request.use(
    config => {
      return config
    },
    error => {
      return Promise.reject(error)
    }
)

// response interceptor
http.interceptors.response.use(
    /**
     * If you want to get http information such as headers or status
     * Please return  response => response
     */
    /**
     * Determine the request status by custom code
     * Here is just an example
     * You can also judge the status by HTTP Status Code
     */
    response => {
      const res = response.data
      if (res.code !== true) {
        if (res.type === 'application/octet-stream') return Promise.resolve(response)
        if (res.code === 0) return Promise.resolve(res)
        return Promise.reject(new Error(res.message || 'Error'))
      } else {
        return Promise.resolve(res)
      }
    },
    error => {
      if (error && error.response) {
        switch (error.response.status) {
          case 403:
            break
          case 401:
            break
          case 404:
            Message.error('请求资源不存在')
            break
          case 500:
            Message.error('系统出现异常，请稍后再试或联系客服获取支持')
            break
          case 503:
            Message.error('服务不可用，请稍后再试')
            break
          default:
            console.log('err:' + error)
        }
      }
      let errorinfo = error.response.data.message
          ? error.response.data.message
          : error.message
      Message.error(errorinfo)
      return Promise.reject(errorinfo)
    }
)

export default http
