<template>
  <el-dialog
      v-model="dialogVisible"
      title="选择班级"
      width="30%" custom-class="class-choose-search" @opened="onopen" :destroy-on-close="true" >
    <el-input v-model="searchInfo" ref="searchInputRef" @keyup.enter="search"
              placeholder="输入 班级名称 / 班级编号 / 备注 关键字查询">
      <template #append>
        <el-button icon="Search" @click="search"/>
      </template>
    </el-input>
    <div style="margin: 8px 0;">
      <el-radio-group v-model="status" @change="search">
        <el-radio-button label="SIGN_UP">报名中</el-radio-button>
        <el-radio-button label="STARTED">培训中</el-radio-button>
        <el-radio-button label="END">已结束</el-radio-button>
        <el-radio-button label="">全部</el-radio-button>
      </el-radio-group>
    </div>
    <el-scrollbar height="200px" always="true">
      <div class="akz-scroll-item" v-for="item in listRender" :key="item.id" @click="chooseClass(item.id,item.className)">
        [{{ item.classCode }}] {{ item.className }} / {{ item.description }}
      </div>
    </el-scrollbar>
    <template #footer>
      <span class="dialog-footer">
        <span style="float: left;width: 200px; text-align: left;font-size: 12px; line-height: 28px;">已选择:
          <span v-if="selected===-1">{{ selectedName }}</span>
          <span style="color:#00CA7A" v-if="selected!==-1">{{ selectedName }}</span>
        </span>
        <el-button @click="closePopup" link class="akz-btn" style="color: #A4A4A4;font-weight: 400">取消</el-button>
        <el-button type="primary" @click="doConfirm" class="akz-btn">选择</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
import {ref} from "vue";
import TrainClassApi from "@/api/TrainClassApi";
import {ElMessage as Message} from 'element-plus'

export default {
  name: "TrainClassSelect",
  mixins: [TrainClassApi],
  setup() {
    const searchInputRef = ref(null)
    return {searchInputRef}
  },
  data() {
    return {
      dialogVisible: false,
      selected: -1,
      selectedName: "未选择",
      searchInfo: "",
      status: "SIGN_UP",
      list: [],
      candidateIds:[]
    }
  },
  props: {
    confirmAction: Function
  },
  methods: {
    setVisible: function (value) {
      this.$data.dialogVisible = value
    },
    setCandidateIds: function (ids){
      this.$data.candidateIds = ids?ids:[]
    },
    closePopup() {
      this.setVisible(false)
    },
    doConfirm() {
      if(this.$data.selected === -1){
        Message.warning("请选择要变更的班级")
        return
      }
      if (this.confirmAction) {
        this.confirmAction(this.$data.candidateIds,this.$data.selected)
      }
      this.setVisible(false)
      this.$data.selected = -1
      this.$data.selectedName = "未选择"
    },
    onopen() {
      this.searchInputRef.focus()
      this.search()
    },
    search() {
      const filter = {
        search: this.$data.searchInfo
      }
      if (this.$data.status !== "") {
        filter["status"] = this.$data.status
      }
      this.apiListSelect(filter).then((response) => {
        if(!response.data.data || response.data.data === null){
          this.$data.list = []
        }else{
          this.$data.list = response.data.data
        }
      })
    },
    chooseClass(id,name){
      this.$data.selected = id
      this.$data.selectedName = name
    }
  },
  computed: {
    listRender: function () {
      return this.$data.list.map((row) => {
        //处理时间显示
/*        row.__createTime = this.dateFormat(row.createTime)
        row.__startTime = this.dateFormat(row.startTime)
        row.__endTime = this.dateFormat(row.endTime)*/
        return row
      })
    }
  }
}
</script>

<style>
.class-choose-search .el-dialog__body {
  padding-top: 0 !important;
}

.akz-scroll-item {
  font-size: 12px;
  padding: 8px 0px;
  cursor: pointer;
}
.akz-scroll-item:hover{
  color: #00CA7A;
}
</style>