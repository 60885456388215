import request from "@/components/util/http";
import Storage from "@/components/util/Storage";

export default {
    mixins:[ Storage ],
    methods:{
        apiOperationLogList(filters,pageSize,pageIndex){
            const supplierId = this.getItem("_supplierId")
            if(supplierId && supplierId.length < 1){
                return
            }
            return request({
                url : '/api/supplier/supplier/operation-log/list/' + supplierId + '/' + pageSize + '/' + pageIndex,
                method: 'get',
                params: filters
            })
        }
    }
}