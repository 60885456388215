<template>
  <ConditionFilter ref="conditionFilter" :filterConfig="filterConfig" @doQuery="doQuery" :show-date-ranger="true"
                   :date-range-default="'thisWeek'"
                   :do-query-when-start="true"
                   :close-after-query="true"></ConditionFilter>
  <div v-loading="loading">
    <div class="statistic-pan">
      <div class="statistic-pan-item">
        <span class="statistic-pan-name">统计周期:</span>
        <span class="statistic-pan-value">{{record.statistic.startTime}} - {{record.statistic.endTime}}</span>
      </div>
      <div class="statistic-pan-item">
        <el-tooltip
            class="box-item"
            effect="light"
            content="统计排除已退款订单"
            placement="top-start">
          <el-icon style="font-size: var(--el-font-size-medium);vertical-align: sub;color: #A4A4A4;margin-right:3px;"><Warning /></el-icon>
        </el-tooltip>
        <span class="statistic-pan-name">订单数:</span>
        <span class="statistic-pan-value">{{record.statistic.orderCount}}</span>
      </div>
      <div class="statistic-pan-item">
        <el-tooltip
            class="box-item"
            effect="light"
            content="统计排除已退款订单"
            placement="top-start">
          <el-icon style="font-size: var(--el-font-size-medium);vertical-align: sub;color: #A4A4A4;margin-right:3px;"><Warning /></el-icon>
        </el-tooltip>
        <span class="statistic-pan-name">预收金额合计:</span>
        <span class="statistic-pan-value">{{record.statistic.preAmount}}</span>
      </div>
      <div class="statistic-pan-item">
        <el-tooltip
            class="box-item"
            effect="light"
            content="统计排除已退款订单"
            placement="top-start">
          <el-icon style="font-size: var(--el-font-size-medium);vertical-align: sub;color: #A4A4A4;margin-right:3px;"><Warning /></el-icon>
        </el-tooltip>
        <span class="statistic-pan-name">实收金额合计:</span>
        <span class="statistic-pan-value">{{record.statistic.amount}}</span>
      </div>
      <el-button link class="akz-link" style="float:right" @click="exportSales">导出结算表</el-button>
    </div>
    <div class="table">
      <el-table
          :data="listData"
          style="width: 100%">
        <el-table-column prop="userName" label="商品分类\商品名称" align="left" header-align="left">
          <template #default="scope">
            {{ scope.row.seriesName }} \ {{ scope.row.categoryName }}<br />{{ scope.row.productName }}
          </template>
        </el-table-column>
        <el-table-column prop="__createTime" label="创建时间" align="center" width="200">
        </el-table-column>
        <el-table-column prop="__price" label="单价" align="right" header-align="right" width="100">
        </el-table-column>
        <el-table-column prop="saleCount" label="数量" align="right" header-align="right" width="100">
        </el-table-column>
        <el-table-column prop="__orderAmounts" label="订单金额" align="right" header-align="right" width="100">
        </el-table-column>
        <el-table-column prop="__exchangeRate" label="交易费率" align="center" header-align="center" width="100">
        </el-table-column>
        <el-table-column prop="__amounts" label="预收金额" align="right" header-align="right" width="100">
        </el-table-column>
        <el-table-column prop="__shareAmounts" label="实收金额" align="right" header-align="right" width="100">
        </el-table-column>
        <el-table-column prop="orderStatus" label="订单状态" align="center" header-align="center" width="100">
        </el-table-column>
      </el-table>
    </div>
    <div class="pagination-item">
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="record.currentIndex"
          :page-sizes="[20, 50, 100]"
          :page-size="record.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="record.total"></el-pagination>
    </div>
  </div>
</template>

<script>
import {ref} from "vue";
import dateFormat from "@/components/util/DateFormat";
import AccountApi from "@/api/AccountApi";
import SupplierSaleOrderApi from "@/api/SupplierSaleOrderApi";
import NumFormat from "@/components/util/NumFormat";
import {ElMessage as Message} from 'element-plus'

export default {
  name: "FinanceSettlement",
  mixins: [dateFormat,NumFormat, AccountApi, SupplierSaleOrderApi],
  setup() {
    const conditionFilter = ref(null)
    return {
      conditionFilter
    }
  },
  data(){
    return {
      loading:false,
      record: {
        list: [],
        currentIndex: 1,
        total: 100,
        pageSize: 20,
        selected: [],
        statistic:{
          startTime:'2000-01-01 00:00:00',
          endTime:'2000-01-01 00:00:00',
          orderCount: 0,
          preAmount: 0,
          amount: 0
        }
      },
      filterConfig: [
        {
          type: 'input',
          title: '商品名称',
          placeHolder: '输入商品关键字',
          bindField: 'productName',
          showInMain: true
        },
        {
          type: 'select',
          title: '订单状态',
          placeHolder: '选择',
          bindField: 'status',
          options: [
            {label: "全部", value: ""},
            {label: "有效", value: "ENABLE"},
            {label: "退回", value: "REFUSE"}
          ],
          showInMain: true
        }
      ]
    }
  }
  ,methods: {
    doQuery(filters) {
      //获取分页信息
      const _currentIndex = this.$data.record.currentIndex
      const _pageSize = this.$data.record.pageSize
      this.$data.loading = true
      //获取页大小和页数
      this.apiSupplierSaleOrderFinanceList(filters, _pageSize, _currentIndex).then((response) => {
        this.$data.loading = false
        this.$data.record.total = response.data.list.total
        this.$data.record.list = response.data.list.data ? response.data.list.data : []
        let {startDate,endDate,orderCount,amount,preAmount} = response.data
        this.$data.record.statistic.startTime = this.dateFormat(startDate)
        this.$data.record.statistic.endTime = this.dateFormat(endDate)
        this.$data.record.statistic.amount = this.currency(amount/100,true)
        this.$data.record.statistic.preAmount = this.currency(preAmount/100,true)
        this.$data.record.statistic.orderCount = orderCount
      })
    },
    search() {
      const filters = this.conditionFilter.getFilters()
      this.doQuery(filters)
    },
    handleSizeChange(pageSize) {
      this.record.pageSize = pageSize
      this.search()
    },
    handleCurrentChange(pageIndex) {
      this.record.currentIndex = pageIndex
      this.search()
    },
    exportSales(){
      const filters = this.conditionFilter.getFilters()
      if(!(filters.startTime && filters.endTime)){
        Message.error("选择导出数据起止时间")
        return;
      }
      const supplierId = this.getItem("_supplierId")
      if(supplierId && supplierId.length < 1){
        return
      }
      let url = "/api/supplier/supplier/sale-order/export/"+supplierId+"?startTime="+filters.startTime+"&endTime="+filters.endTime
      let a = document.createElement("a");
      a.setAttribute("href", url);
      a.setAttribute("target", "_blank");
      a.setAttribute("id", "download-link");
      document.body.appendChild(a);
      a.click();
      document.getElementById("download-link").remove()
    }
  },
  computed: {
    listData: function () {
      return this.$data.record.list.map((row) => {
        //处理时间显示
        row.__createTime = this.dateFormat(row.createTime)
        row.__shareAmounts = this.currency(row.shareAmounts/100)
        row.__amounts = this.currency((row.amounts/100 - row.amounts*(0.0+row.exchangeRate)/1000000))
        row.__orderAmounts = this.currency(row.amounts/100)
        row.__price = this.currency(row.price/100)
        row.__exchangeRate = (0.0+row.exchangeRate)/10000
        return row
      })
    }
  }
}
</script>

<style scoped>
  .statistic-pan{
    display: block;
    background-color: white;
    font-size: var(--el-font-size-base);
    padding: 20px;
    border-bottom: 1px solid #ebeef5 !important;
  }
  .statistic-pan .statistic-pan-item{
    display: inline-block;
  }
  .statistic-pan .statistic-pan-item .statistic-pan-name{
    color: #A4A4A4
  }
  .statistic-pan .statistic-pan-item .statistic-pan-value{
    margin-left: 6px;
    margin-right: 30px;
  }

</style>