<template>
  <ConditionFilter :filterConfig="filterConfig" @doQuery="search" :do-query-when-start="false"
                   :close-after-query="true" ref="conditionFilter"></ConditionFilter>
  <div class="feature-pan">
    <el-button type="primary" @click="openNew" style="margin-bottom: 10px;">新增</el-button>
  </div>
  <div v-loading="loading">
    <ListHeaderTabs :default-tab-id="1" :configs="tabsConfig" @tab-change="search" ref="tabFilter"></ListHeaderTabs>
    <div class="table" >
      <el-table
          :data="listData"
          style="width: 100%"
      >
        <el-table-column prop="classCode" label="班级编号" align="left" header-align="left" width="200">
          <template #default="scope">
            <span v-if="scope.row.classStatus==='SIGN_UP'" class="class-status-tag __sign_up">报名中</span>
            <span v-if="scope.row.classStatus==='STARTED'" class="class-status-tag __started">培训中</span>
            <span v-if="scope.row.classStatus==='END'" class="class-status-tag __end">已结束</span>
            {{ scope.row.classCode }}
          </template>
        </el-table-column>
        <el-table-column prop="className" label="班级名称" align="left" header-align="left" width="250">
          <template #default="scope">
            <el-button link class="akz-link" @click="edit(scope.row.id)">{{scope.row.className}}</el-button>
          </template>
        </el-table-column>
        <el-table-column prop="memberCount" label="学员数" align="center" header-align="center"  width="80"></el-table-column>
        <el-table-column prop="description" label="备注" align="left" header-align="left">
        </el-table-column>
        <el-table-column prop="subjectType" label="创建\开班时间" align="left" header-align="left" width="200px">
          <template #default="scope">
            {{ scope.row.__createTime }}\<br />{{ scope.row.__startTime }}
          </template>
        </el-table-column>
        <el-table-column label="操作" width="80" align="left">
          <template #default="scope">
            <el-button link class="akz-link" @click="edit(scope.row.id)">管理</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="pagination-item">
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="record.currentIndex"
          :page-sizes="[20, 50, 100]"
          :page-size="record.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="record.total"></el-pagination>
    </div>
  </div>

  <!--  详情  -->
  <el-drawer v-model="detail.show" :show-close="true" custom-class="detail-draw-pan akz-no-border-tabs" size="80%">
    <template #header="{titleId, titleClass }">
      <h4 :id="titleId" :class="titleClass" style="color: #000;font-weight: 650;font-size: 14px;">
        <DataLine style="width: 16px; height: 16px;" />
        <span style="margin-left: 8px;">班级管理</span></h4>
    </template>
    <el-tabs tab-position="left" style="height: 100%" v-model="detailTab.showTabName">
      <el-tab-pane name="students" label="学员信息">
        <el-scrollbar :height="windowHeight">
          <div class="akz-pan" style="margin-left: 10px; margin-right: 10px;">
            <div class="table-multi-btns" style="margin-bottom: 20px;">
              <span>批量操作：</span>
              <el-button link class="akz-link" @click="openMemberDescriptionUpdateBatch">备注</el-button>
              <el-button link class="akz-link" @click="changeTrainClassBatch">转班</el-button>
            </div>
            <el-table
                :data="listMembersData"
                @selection-change="handleSelectionChange"
            >
              <el-table-column type="selection" width="50" align="center">
              </el-table-column>
              <el-table-column prop="studentName" label="姓名" align="left" header-align="left" width="100">
              </el-table-column>
              <el-table-column prop="description" label="备注" align="left" header-align="left">
              </el-table-column>
              <el-table-column prop="__signUpDate" label="报名时间" align="center" width="150" header-align="center">
              </el-table-column>
              <el-table-column prop="__entranceDate" label="进班时间" align="center" width="150" header-align="center">
              </el-table-column>
              <el-table-column label="操作" width="200" align="center" header-align="center">
                <template #default="scope">
                  <el-button link class="akz-link" @click="openTrainOrder(scope.row.studentOrderNo)">查看</el-button>
                  <el-button link class="akz-link" @click="selectClass(scope.row.id)">转班</el-button>
                  <el-button link class="akz-link" @click="openMemberDescriptionUpdate([scope.row.id],scope.row.description)">备注</el-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </el-scrollbar>
      </el-tab-pane>
      <el-tab-pane name="basic" label="班级信息">
        <el-scrollbar :height="windowHeight">
          <div class="tab-pan">
            <div style="margin-left: 30px">
              <el-radio-group v-model="detail.classStatus" size="large" @change="updateStatus">
                <el-radio-button label="SIGN_UP" >报名中</el-radio-button>
                <el-radio-button label="STARTED">培训中</el-radio-button>
                <el-radio-button label="END">已结束</el-radio-button>
              </el-radio-group>
              <span style="vertical-align: bottom;font-size: 12px;color: #A4A4A4;margin-left: 20px;">*点击切换班级状态</span>
            </div>
            <div class="akz-pan" style="margin-left: 10px; margin-right: 10px;">
              <div class="akz-pan-title">
                基本信息
              </div>
              <div class="akz-pan-content">
                <el-row :gutter="10">
                  <el-col :span="4" :class="'field-name'">
                    班级编号
                  </el-col>
                  <el-col :span="20">
                    {{ detail.classCode }}
                  </el-col>
                </el-row>
                <el-row :gutter="10">
                  <el-col :span="4" :class="'field-name'">
                    班级名称
                  </el-col>
                  <el-col :span="20" class="akz-break-word">
                    {{ detail.className }}
                    <el-button link class="akz-link" @click="openClassNameUpdate(detail.id,detail.className)">变更</el-button>
                  </el-col>
                </el-row>
                <el-row :gutter="10">
                  <el-col :span="4" :class="'field-name'">
                    创建时间
                  </el-col>
                  <el-col :span="20">
                    {{ detail.__createTime }}
                  </el-col>
                </el-row>
                <el-row :gutter="10">
                  <el-col :span="4" :class="'field-name'">
                    备注
                  </el-col>
                  <el-col :span="20" class="akz-break-word">
                    {{ detail.description }}
                    <el-button link class="akz-link" @click="openDescriptionUpdate(detail.id,detail.description)">编辑</el-button>
                  </el-col>
                </el-row>
              </div>
            </div>
            <div class="akz-pan" style="margin-left: 10px; margin-right: 10px;">
              <div class="akz-pan-title">
                培训时间
              </div>
              <div class="akz-pan-content">
                <el-row :gutter="10">
                  <el-col :span="4" :class="'field-name'">
                    预计开始时间
                  </el-col>
                  <el-col :span="20">
                    {{ detail.__startTime }}
                    <el-button link class="akz-link" @click="openStartTimeUpdate(detail.id,detail.startTime)">变更</el-button>
                  </el-col>
                </el-row>
                <el-row :gutter="10">
                  <el-col :span="4" :class="'field-name'">
                    预计结束时间
                  </el-col>
                  <el-col :span="20">
                    {{ detail.__endTime }}
                    <el-button link class="akz-link" @click="openEndTimeUpdate(detail.id,detail.endTime)">变更</el-button>
                  </el-col>
                </el-row>
              </div>
            </div>
          </div>
        </el-scrollbar>
      </el-tab-pane>
    </el-tabs>
  </el-drawer>

  <!-- 弹窗 -->
  <PopupForm :show-popup="newRecord.show" pop-title="新增班级" :confirm-action="doAddNew"
             @close-popup="closeNew" :form-config="newRecord.config" :label-width="'120px'"></PopupForm>
  <PopupForm :show-popup="updateClassName.show" pop-title="变更班级名称" :confirm-action="doUpdateClassName"
             @close-popup="closeClassNameUpdate" :form-config="updateClassName.config" ref="classNameUpdatePop"></PopupForm>
  <PopupForm :show-popup="updateDescription.show" pop-title="编辑备注" :confirm-action="doUpdateDescription"
             @close-popup="closeDescriptionUpdate" :form-config="updateDescription.config" ref="descriptionUpdatePop"></PopupForm>
  <PopupForm :show-popup="updateStartTime.show" pop-title="编辑预计开始时间" :confirm-action="doUpdateStartTime" :label-width="'120px'"
             @close-popup="closeStartTimeUpdate" :form-config="updateStartTime.config" ref="startTimeUpdatePop"></PopupForm>
  <PopupForm :show-popup="updateEndTime.show" pop-title="编辑预计结束时间" :confirm-action="doUpdateEndTime" :label-width="'120px'"
             @close-popup="closeEndTimeUpdate" :form-config="updateEndTime.config" ref="endTimeUpdatePop"></PopupForm>
  <TrainClassSelect ref="trainClassSelect" :confirm-action="doUpdateTrainClass"></TrainClassSelect>
  <SignMngDetail ref="signMngDetail" />
  <PopupForm :show-popup="updateMemberDescription.show" pop-title="编辑学员备注" :confirm-action="doUpdateMemberDescription"
             @close-popup="closeMemberDescriptionUpdate" :form-config="updateMemberDescription.config" ref="memberDescriptionUpdatePop"></PopupForm>
</template>

<script>
import ListHeaderTabs from "@/components/frame/tabs/ListHeaderTabs";
import TrainClassApi from "@/api/TrainClassApi";
import TrainClassMemberApi from "@/api/TrainClassMemberApi";
import TrainClassSelect from "@/components/trainClass/TrainClassSelect";
import {ref} from "vue";
import dateFormat from "@/components/util/DateFormat";
import {ElMessage as Message} from 'element-plus'
import SignMngDetail from "@/components/orderMng/signMng/SignMngDetail";

export default {
  name: "TrainClassMng",
  components: {ListHeaderTabs,TrainClassSelect,SignMngDetail},
  mixins:[ TrainClassApi,TrainClassMemberApi,dateFormat ],
  setup() {
    const conditionFilter = ref(null)
    const tabFilter = ref(null)
    const classNameUpdatePop = ref(null)
    const descriptionUpdatePop = ref(null)
    const startTimeUpdatePop = ref(null)
    const endTimeUpdatePop = ref(null)
    const trainClassSelect = ref(null)
    const signMngDetail = ref(null)
    const memberDescriptionUpdatePop = ref(null)
    return {
      conditionFilter,tabFilter,classNameUpdatePop,descriptionUpdatePop,startTimeUpdatePop,endTimeUpdatePop
      ,trainClassSelect,signMngDetail,memberDescriptionUpdatePop
    }
  },
  data() {
    return {
      windowHeight: '400px',
      loading: false,
      record: {
        list: [],
        currentIndex: 1,
        total: 100,
        pageSize: 20,
        selected: []
      },
      classMembers:{
        list: []
      },
      studentSelected:[],
      filterConfig: [
        {
          type: 'input',
          title: '班级编号',
          placeHolder: '请输入...',
          bindField: 'classCode',
          showInMain: true
        },
        {
          type: 'input',
          title: '班级名称',
          placeHolder: '请输入...',
          bindField: 'className',
          showInMain: true
        },{
          type: 'input',
          title: '备注',
          placeHolder: '请输入备注关键字...',
          bindField: 'description'
        }
      ],
      tabsConfig: [
        {id: 1, tabName: "报名中",value : "SIGN_UP"},
        {id: 2, tabName: "培训中",value : "STARTED"},
        {id: 3, tabName: "已结束",value: "END"},
        {id: 4, tabName: "全部",value : ""}
      ],
      detail: {
        show: false,
      },
      detailTab:{
        showTabName: "students"
      },
      newRecord: {
        show: ref(false),
        config: [{
          type: 'input',
          title: '班级名称',
          bindField: 'className',
          rules: [{
            required: true,
            message: '请输入班级名称，长度小于100字',
            trigger: 'blur',
            pattern: /^.{1,100}$/
          }]
        }, {
          type: 'date',
          title: '预计开班时间',
          bindField: 'startTime',
          rules: [{required: true, message: '请选择预计开班时间', trigger: 'blur'}]
        }, {
          type: 'date',
          title: '预计结束时间',
          bindField: 'endTime',
          rules: [{required: true, message: '请选择预计结束时间', trigger: 'blur'}]
        }, {
          type: 'select',
          title: '班级状态',
          bindField: 'classStatus',
          options:[
            {label:"报名中",value:"SIGN_UP"},{label:"培训中",value:"STARTED"},{label:"已结束",value:"END"}
          ],
          rules: [{required: true, message: '请选择预计结束时间', trigger: 'blur'}]
        },{
          type: 'area',
          title: '备注',
          placeHolder: '(非必填)',
          bindField: 'description',
          rowCount: 9,
          rules: [{
            required: false,
            message: '长度小于1000字',
            trigger: 'blur',
            pattern: /^.{0,1000}$/
          }]
        }]
      },
      updateClassName: {
        show: ref(false),
        id : -1,
        config: [
          {
            type: 'input',
            title: '班级名称',
            bindField: 'className',
            defaultValue: "",
            rules: [{
              required: true,
              message: '请输入班级名称，长度小于100字',
              trigger: 'blur',
              pattern: /^.{1,100}$/
            }]
          }
        ]
      },
      updateDescription: {
        show: ref(false),
        id : -1,
        config: [
          {
            type: 'area',
            title: '备注',
            placeHolder: '(非必填)',
            bindField: 'description',
            rowCount: 9,
            rules: [{
              required: false,
              message: '长度小于1000字',
              trigger: 'blur',
              pattern: /^.{0,1000}$/
            }]
          }
        ]
      },
      updateStartTime: {
        show: ref(false),
        id : -1,
        config: [
          {
            type: 'date',
            title: '预计开始时间',
            placeHolder: '请选择预计开始时间',
            bindField: 'startTime',
            rules: [
                {required: true,message: '请选择预计开始时间',trigger: 'blur'}
            ]
          }
        ]
      },
      updateEndTime: {
        show: ref(false),
        id : -1,
        config: [
          {
            type: 'date',
            title: '预计结束时间',
            placeHolder: '请选择预计结束时间',
            bindField: 'endTime',
            rules: [
              {required: true,message: '请选择预计结束时间',trigger: 'blur'}
            ]
          }
        ]
      },
      updateMemberDescription: {
        show: ref(false),
        id : -1,
        config: [
          {
            type: 'area',
            title: '备注',
            placeHolder: '(非必填)',
            bindField: 'description',
            rowCount: 9,
            rules: [{
              required: false,
              message: '长度小于1000字',
              trigger: 'blur',
              pattern: /^.{0,1000}$/
            }]
          }
        ]
      },
    }
  },
  methods:{
    doQuery(filters) {
      //获取分页信息
      const _currentIndex = this.$data.record.currentIndex
      const _pageSize = this.$data.record.pageSize

      //获取页大小和页数
      this.apiList(filters, _pageSize, _currentIndex).then((response) => {
        this.$data.loading = false
        this.$data.record.total = response.data.total
        this.$data.record.list = response.data.data ? response.data.data : []
      })
    },
    _search(){
      this.tabFilter.changeTab(4)
      this.search()
    },
    search() {
      this.$data.loading = true
      const filters = this.conditionFilter.getFilters()
      const tabIndex = this.tabFilter.getFilters()
      let tabFilterValue
      this.$data.tabsConfig.forEach(x=>{
        if(x.id === tabIndex){
          tabFilterValue = x.value
        }
      })
      if(tabFilterValue !== ""){
        filters["status"] = tabFilterValue
      }

      this.doQuery(filters)
    },
    handleSizeChange(pageSize) {
      this.record.pageSize = pageSize
      this.search()
    },
    handleCurrentChange(pageIndex) {
      this.record.currentIndex = pageIndex
      this.search()
    },
    edit(id) {
      const filteredData = this.$data.record.list.filter((x) => x.id === id);
      if (filteredData.length > 0) {
        let detailData = JSON.parse(JSON.stringify(filteredData[0]));
        detailData.show = true
        this.$data.detailTab.showTabName = "students"
        this.$data.detail = detailData
        //加载学员列表
        this.loadMembers()
      }
    },
    /* 新增 */
    openNew() {
      this.$data.newRecord.show = true
    },
    closeNew() {
      this.$data.newRecord.show = false
    },
    doAddNew(data) {
      this.apiAdd(data).then(() => {
        this.closeNew()
        this.search()
      }).catch(error => {
        Message.error(error.message)
      })
    },
    /* 更新状态 */
    updateStatus(value){
      const updateId = this.$data.detail.id
      this.apiUpdateStatus(updateId,value).then(()=>{
        this.search()
      })
    },
    /* 更新班级名称 */
    doUpdateClassName(data){
      this.apiUpdateClassName(this.$data.updateClassName.id,data.className).then(()=>{
        this.$data.detail.className = data.className
        this.closeClassNameUpdate()
        this.search()
      })
    },
    openClassNameUpdate(id,value) {
      this.$data.updateClassName.show = true
      this.$data.updateClassName.id = id
      this.classNameUpdatePop.initialData({className: value})
    },
    closeClassNameUpdate(){
      this.$data.updateClassName.show = false
    },
    /* 更新班级备注 */
    doUpdateDescription(data){
      this.apiUpdateDescription(this.$data.updateDescription.id,data.description).then(()=>{
        this.$data.detail.description = data.description
        this.closeDescriptionUpdate()
        this.search()
      })
    },
    openDescriptionUpdate(id,value) {
      this.$data.updateDescription.show = true
      this.$data.updateDescription.id = id
      this.descriptionUpdatePop.initialData({description: value})
    },
    closeDescriptionUpdate(){
      this.$data.updateDescription.show = false
    },
    /* 更新预计开始时间 */
    doUpdateStartTime(data){
      this.apiUpdateStartTime(this.$data.updateStartTime.id,data.startTime).then(()=>{
        this.$data.detail.startTime = data.startTime
        this.$data.detail.__startTime = this.dateFormat(data.startTime)
        this.closeStartTimeUpdate()
        this.search()
      })
    },
    openStartTimeUpdate(id,value) {
      this.$data.updateStartTime.show = true
      this.$data.updateStartTime.id = id
      this.startTimeUpdatePop.initialData({startTime: value})
    },
    closeStartTimeUpdate(){
      this.$data.updateStartTime.show = false
    },
    /* 更新预计结束时间 */
    doUpdateEndTime(data){
      this.apiUpdateEndTime(this.$data.updateEndTime.id,data.endTime).then(()=>{
        this.$data.detail.endTime = data.endTime
        this.$data.detail.__endTime = this.dateFormat(data.endTime)
        this.closeEndTimeUpdate()
        this.search()
      })
    },
    openEndTimeUpdate(id,value) {
      this.$data.updateEndTime.show = true
      this.$data.updateEndTime.id = id
      this.endTimeUpdatePop.initialData({endTime: value})
    },
    closeEndTimeUpdate(){
      this.$data.updateEndTime.show = false
    },
    /* 学员信息 */
    loadMembers(){
      this.$data.classMembers.list = []
      const classId = this.$data.detail.id
      if(classId) {
        this.apiMemberList(classId).then((response)=>{
          if(response.data){
            this.$data.classMembers.list = response.data
          }
        })
      }
    },
    selectClass(id){
      this.trainClassSelect.setVisible(true)
      this.trainClassSelect.setCandidateIds([id])
    },
    doUpdateTrainClass(ids,classId){
      this.apiUpdateMemberClass(ids,classId).then(()=>{
        this.loadMembers()
        this.search()
      })
    },
    changeTrainClassBatch(){
      if(this.$data.record.studentSelected.length < 1){
        Message.warning("请选择要转班的学员")
        return
      }
      this.trainClassSelect.setVisible(true)
      this.trainClassSelect.setCandidateIds(this.$data.record.studentSelected)
    },
    handleSelectionChange(changed) {
      this.$data.record.studentSelected = changed.map(x => x.id)
    },
    openTrainOrder(sSaleOrderNo){
      this.signMngDetail.openDetail(sSaleOrderNo)
    },
    /* 更新班级学员备注 */
    doUpdateMemberDescription(data){
      this.apiUpdateMemberDescription(this.$data.detail.id,this.$data.updateMemberDescription.id,data.description).then(()=>{
        this.closeMemberDescriptionUpdate()
        //加载学员列表
        this.loadMembers()
      })
    },
    openMemberDescriptionUpdate(id,value) {
      this.$data.updateMemberDescription.show = true
      this.$data.updateMemberDescription.id = id
      this.memberDescriptionUpdatePop.initialData({description: value})
    },
    openMemberDescriptionUpdateBatch() {
      this.openMemberDescriptionUpdate(this.$data.record.studentSelected,"")
    },
    closeMemberDescriptionUpdate(){
      this.$data.updateMemberDescription.show = false
    },
  },
  computed: {
    listData: function () {
      return this.$data.record.list.map((row) => {
        //处理时间显示
        row.__createTime = this.dateFormat(row.createTime)
        row.__startTime = this.dateFormat(row.startTime)
        row.__endTime = this.dateFormat(row.endTime)
        if(row.memberCount === null){
          row.memberCount = 0
        }
        return row
      })
    },
    listMembersData: function (){
      return this.$data.classMembers.list.map((row) => {
        row.__signUpDate = this.dateFormat(row.signUpDate)
        row.__entranceDate = this.dateFormat(row.entranceDate)
        return row
      })
    }
  },
  mounted() {
    //重置tabContent高度
    const _this = this
    this.$data.windowHeight = (window.innerHeight - 53) + 'px'
    window.onresize = function () {
      _this.$data.windowHeight = (window.innerHeight - 53) + 'px'
    }
    this.search()
  }
}
</script>

<style scoped>
  .akz-break-word{
    word-break: break-all;
  }
  .class-status-tag {
    padding: 2px 4px;
    color: #ffffff;
    white-space: nowrap;
  }
  .class-status-tag.__sign_up{
    background-color: #909399;
  }
  .class-status-tag.__started{
    background-color: #409EFF;
  }
  .class-status-tag.__end{
    background-color: #00CA7A;
  }
</style>