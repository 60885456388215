import request from "@/components/util/http";

export default {
    methods:{
        apiListAllFeature(){
            return request({
                url: '/api/supplier/supplier/features',
                method: 'get'
            })
        }
    }
}