import request from "@/components/util/http";
import Storage from "@/components/util/Storage";

export default {
    mixins:[ Storage ],
    methods:{
        //查询帐号列表
        apiList(filters,pageSize,pageIndex){
            const supplierId = this.getItem("_supplierId")
            if(supplierId && supplierId.length < 1){
                return
            }
            return request({
                url: '/api/supplier/supplier/supplier-user/list/' + supplierId + '/' + pageSize + '/' + pageIndex,
                method: 'get',
                params: filters
            })
        },
        //更新状态 status:ENABLE,DISABLE
        apiUpdateStatus(ids,status){
            const supplierId = this.getItem("_supplierId")
            if(supplierId && supplierId.length < 1){
                return
            }
            return request({
                url: '/api/supplier/supplier/supplier-user/'+supplierId+'/status',
                method: 'put',
                data: {
                    status: status,
                    supplierUserIds : ids
                }
            })
        },
        apiResetPwd(id,newPwd){
            const supplierId = this.getItem("_supplierId")
            if(supplierId && supplierId.length < 1){
                return
            }
            return request({
                url: '/api/supplier/supplier/supplier-user/pwd/reset/'+supplierId,
                method: 'post',
                data:{
                    supplierUserId:id,
                    pwd: newPwd
                }
            })
        },
        apiUpdateNickName(id,newNickName){
            const supplierId = this.getItem("_supplierId")
            if(supplierId && supplierId.length < 1){
                return
            }
            return request({
                url: '/api/supplier/supplier/supplier-user/nick-name/'+supplierId+"/"+id+"?newNickName="+newNickName,
                method: 'put'
            })
        },
        apiUpdateComment(id,newDescription){
            const supplierId = this.getItem("_supplierId")
            if(supplierId && supplierId.length < 1){
                return
            }
            return request({
                url: '/api/supplier/supplier/supplier-user/description/'+supplierId+"/"+id+"?newDescription="+newDescription,
                method: 'put'
            })
        },
        apiRoles(){
            return request({
                url: '/api/supplier/supplier/supplier-user-role',
                method: 'get'
            })
        },
        //新增
        apiAdd(params){
            const supplierId = this.getItem("_supplierId")
            if(supplierId && supplierId.length < 1){
                return
            }
            params["status"] = params.supplierStatus === true?'ENABLE':'DISABLE'
            params["supplierId"] = supplierId
            return request({
                url : '/api/supplier/supplier/supplier-user',
                method: 'post',
                data: params,
                params:{
                    supplierId: supplierId
                }
            })
        },
        //删除
        apiDelete(supplierUserId){
            const supplierId = this.getItem("_supplierId")
            if(supplierId && supplierId.length < 1){
                return
            }
            return request({
                url : '/api/supplier/supplier/supplier-user/'+supplierId+'/'+supplierUserId,
                method: 'delete'
            })
        },
        //日志下拉用
        apiHistory(){
            const supplierId = this.getItem("_supplierId")
            if(supplierId && supplierId.length < 1){
                return
            }
            return request({
                url: '/api/supplier/supplier/supplier-user/histories/'+supplierId,
                method : 'get'
            })
        },
        //为子帐号新增角色
        apiAddRole(id,role){
            const supplierId = this.getItem("_supplierId")
            if(supplierId && supplierId.length < 1){
                return
            }
            return request({
                url: '/api/supplier/supplier/supplier-user-role/role/'+supplierId+"/"+id+"/"+role,
                method : 'put'
            })
        },
        apiDeleteRole(id,role){
            const supplierId = this.getItem("_supplierId")
            if(supplierId && supplierId.length < 1){
                return
            }
            return request({
                url: '/api/supplier/supplier/supplier-user-role/role/'+supplierId+"/"+id+"/"+role,
                method : 'delete'
            })
        },
        apiUpdateOperatorNickName(newNickName){
            return request({
                url: '/api/supplier/supplier/supplier-user/nick-name?newNickName='+newNickName,
                method: 'put'
            })
        },
        apiUpdateOperatorPwd(oldPwd,newPwd){
            return request({
                url: '/api/supplier/supplier/supplier-user/pwd/reset',
                method: 'put',
                data:{
                    oldPwd: oldPwd,
                    newPwd: newPwd
                }
            })
        },
        apiSendPhoneBindSms(phone){
            return request({
                url: '/api/supplier/supplier/supplier-user/phone-validate/'+phone,
                method : "post"
            })
        },
        apiDoBindPhone(phone,validateCode){
            return request({
                url: '/api/supplier/supplier/supplier-user/phone-bind',
                method: 'post',
                data:{
                    phone: phone,
                    validateCode: validateCode
                }
            })
        },
        apiGetOperatorInfo(){
            return request({
                url: '/api/supplier/supplier/supplier-user',
                method : "get"
            })
        }
    },
}

