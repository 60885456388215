import request from "@/components/util/http";
import Storage from "@/components/util/Storage";

export default {
    mixins:[ Storage ],
    methods:{
        apiSupplierSaleOrderFinanceList(filters,pageSize,pageIndex){
            const supplierId = this.getItem("_supplierId")
            if(supplierId && supplierId.length < 1){
                return
            }
            return request({
                url : '/api/supplier/supplier/sale-order/list/' + supplierId + '/' + pageSize + '/' + pageIndex,
                method: 'get',
                params: filters
            })
        },
        apiSupplierSaleOrderList(filters,pageSize,pageIndex){
            const supplierId = this.getItem("_supplierId")
            if(supplierId && supplierId.length < 1){
                return
            }
            return request({
                url : '/api/supplier/supplier/sale-order/list-orders/' + supplierId + '/' + pageSize + '/' + pageIndex,
                method: 'get',
                params: filters
            })
        },
        apiSupplierGetBillOutList(filters,pageSize,pageIndex){
            const supplierId = this.getItem("_supplierId")
            if(supplierId && supplierId.length < 1){
                return
            }
            return request({
                url : '/api/supplier/supplier/sale-order/list-bill-out/' + supplierId + '/' + pageSize + '/' + pageIndex,
                method: 'get',
                params: filters
            })
        },
        apiSupplierUpdateBillOutStatus(orderNos,targetStatus){
            const supplierId = this.getItem("_supplierId")
            if(supplierId && supplierId.length < 1){
                return
            }
            return request({
                url : '/api/supplier/supplier/sale-order/bill-out/' + supplierId,
                method: 'put',
                data: {
                    orderNos: orderNos,
                    status: targetStatus
                }
            })
        }
    }
}