<template>
  <el-container id="main-container">
    <el-header height="56px" :style="{
      zIndex: 2,
      padding:'0 0 0 23px',
      backgroundColor:'white',
      boxShadow: `var(${getCssVarName('light')})`}">
      <el-menu
          class="top-bar"
          mode="horizontal"
          :ellipsis="false">
<!--        <el-image src="require('assets/logo.png')" class="logo-pan"></el-image>-->
        <el-image :src="require('@/assets/logo.png')" class="logo-pan"></el-image>
        <div class="flex-grow"/>
        <el-sub-menu index="1">
          <template #title>
            <Setting style="width: 1.1em; height: 1.1em; margin-right: 8px" color="#999999"/>
            {{ nickName }}
          </template>
          <el-menu-item index="2-2" @click="openUserSettings">帐号管理</el-menu-item>
          <el-menu-item index="2-3" @click="quitApp">退出</el-menu-item>
        </el-sub-menu>
      </el-menu>
    </el-header>
    <el-container>
      <el-aside width="200px" style="background-color: #304056">
        <el-menu id="main-menu"
            active-text-color="#00CA7A"
            background-color="#304056"
            class="el-menu-vertical-demo"
            default-active="0"
            text-color="#fff">
          <div v-for="item in menuRoleFilter" :key="item.key">
            <el-menu-item v-if="typeof item.subs === 'undefined'" :index="item.key" @click="openPage(item)">
              <el-icon v-if="item.icon && item.icon!==''">
                <Component :is="item.icon" style="font-size: 14px"></Component>
              </el-icon>
              <span>{{ item.showName }}</span>
            </el-menu-item>
            <el-sub-menu v-else :index="item.key">
              <template #title>
                <el-icon>
                  <Component :is="item.icon" style="font-size: 14px"></Component>
                </el-icon>
                {{ item.showName }}
              </template>
              <el-menu-item v-for="subItem in item.subs" :key="subItem.key" :index="subItem.key" @click="openPage(subItem)">
                {{ subItem.showName }}
              </el-menu-item>
            </el-sub-menu>
          </div>
        </el-menu>
      </el-aside>
      <el-main style="padding: 0" class="module-container-pan">
        <el-tabs v-model="activeModule" type="border-card" class="module-tabs" @tab-remove="removeTab">
          <el-tab-pane v-for="page in pages" :label="page.tabName" :name="page.id" :closable="page.closable" :key="page.id">
            <el-scrollbar :height="windowHeight">
            <keep-alive>
              <Component :is="page.componentName" @gotoModule="goToModule"></Component>
            </keep-alive>
            </el-scrollbar>
          </el-tab-pane>
        </el-tabs>
        <i v-if="showAjaxLoading" class="loading-icon el-icon is-loading">
          <svg class="circular" viewBox="0 0 1024 1024">
            <path
                class="path"
                d="M512 64a32 32 0 0 1 32 32v192a32 32 0 0 1-64 0V96a32 32 0 0 1 32-32zm0 640a32 32 0 0 1 32 32v192a32 32 0 1 1-64 0V736a32 32 0 0 1 32-32zm448-192a32 32 0 0 1-32 32H736a32 32 0 1 1 0-64h192a32 32 0 0 1 32 32zm-640 0a32 32 0 0 1-32 32H96a32 32 0 0 1 0-64h192a32 32 0 0 1 32 32zM195.2 195.2a32 32 0 0 1 45.248 0L376.32 331.008a32 32 0 0 1-45.248 45.248L195.2 240.448a32 32 0 0 1 0-45.248zm452.544 452.544a32 32 0 0 1 45.248 0L828.8 783.552a32 32 0 0 1-45.248 45.248L647.744 692.992a32 32 0 0 1 0-45.248zM828.8 195.264a32 32 0 0 1 0 45.184L692.992 376.32a32 32 0 0 1-45.248-45.248l135.808-135.808a32 32 0 0 1 45.248 0zm-452.544 452.48a32 32 0 0 1 0 45.248L240.448 828.8a32 32 0 0 1-45.248-45.248l135.808-135.808a32 32 0 0 1 45.248 0z"
                fill="currentColor"
            />
          </svg>
        </i>
      </el-main>
    </el-container>
  </el-container>
  <UserSettings ref="userSettings" />
</template>

<script>

import ProHome from "@/components/home/ProHome";
import SignMng from "@/components/orderMng/signMng/SignMng";
import BillOutMng from "@/components/orderMng/billOutMng/BillOutMng";
import AccountManagement from "@/components/account/AccountManagement";
import OperationLogView from "@/components/management/operationLog/OperationLogView";
import FinanceSettlement from "@/components/finance/financeSettlement/FinanceSettlement";
import TrainClassMng from "@/components/trainClass/TrainClassMng";
import OrderMng from "@/components/orderMng/orders/OrderMng";
import Storage from "@/components/util/Storage";
import LoginApi from "@/api/LoginApi";
import {useStore} from 'vuex'
import {useRouter} from "vue-router";
import {computed, ref} from 'vue'
import UserSettings from "@/components/user/UserSettings";

export default {
  name: "home-frame",
  components:{ProHome,SignMng,BillOutMng,AccountManagement,OperationLogView,FinanceSettlement,OrderMng,TrainClassMng,
    UserSettings},
  mixins:[ Storage,LoginApi ],
  setup(){
    const router = useRouter()
    const store = useStore()
    const userSettings = ref(null)

    return { router, showAjaxLoading: computed(() => store.state.showAjaxLoading),userSettings}
  },
  data() {
    return {
      activeModule: 0,
      nickName: '',
      windowHeight: 400,
      menu: [
        {
          showName: "首页",
          key: "1",
          icon: "Menu",
          componentName: "ProHome",
          closable: false,
          roles:["MANAGER","FINANCIAL_MANAGER","SIGN_MANAGER"]
        },
        {
          showName: "订单管理",
          key: "2",
          icon: "Tickets",
          subs: [
            {
              showName: "报名处理",
              key: "2-1",
              icon: "",
              componentName: "SignMng",
              roles:["MANAGER","SIGN_MANAGER"]
            },
            {
              showName: "销售记录",
              key: "2-2",
              icon: "",
              componentName: "OrderMng",
              roles:["MANAGER","SIGN_MANAGER"]
            }
          ]
        },
        {
          showName: "培训管理",
          key: "3",
          icon: "Microphone",
          subs: [
            {
              showName: "班级管理",
              key: "3-1",
              componentName: "TrainClassMng",
              roles:["MANAGER","SIGN_MANAGER"]
            }
          ]
        },
        {
          showName: "资金管理",
          key: "4",
          icon: "ShoppingBag",
          subs: [
            {
              showName: "资金结算",
              key: "4-1",
              componentName: "FinanceSettlement",
              roles:["MANAGER","FINANCIAL_MANAGER"]
            },
            {
              showName: "发票开具",
              key: "4-2",
              icon: "",
              componentName: "BillOutMng",
              roles:["MANAGER","FINANCIAL_MANAGER"]
            }
          ]
        },
        {
          showName: "系统管理",
          key: "5",
          icon: "SetUp",
          subs: [
            {
              showName: "子账号管理",
              key: "5-1",
              icon: "",
              componentName: "AccountManagement",
              roles:["MANAGER"]
            },
            {
              showName: "操作记录",
              key: "5-2",
              icon: "",
              componentName: "OperationLogView",
              roles:["MANAGER"]
            }
          ]
        }
      ],
      pages:[]
    }
  },
  mounted() {
    const _this = this
    /**
     * 检查登录状态
     */
    const token = this.getItem("_token")
    if(token && token.length > 0){
      this.checkIsLogin(token).then((response)=>{
        if(response.data === true || response.data){
          //重置tabContent高度
          const resizeRollbar = function () {
            _this.$data.windowHeight = (window.innerHeight - 115)
          }
          resizeRollbar()
          window.addEventListener(
              "resize",resizeRollbar,false
          );

          //打开首页或url中指定页面
          const url = window.location.href.split("#");
          let forwardModuleName
          if(url.length > 1){
            forwardModuleName = url[url.length-1]
          }else{
            forwardModuleName = "ProHome";
          }
          let homeMenu = this.findModuleForName(forwardModuleName);
          if(typeof homeMenu === "undefined"){
            forwardModuleName = "ProHome";
          }
          homeMenu = this.findModuleForName(forwardModuleName);
          this.$data.pages.push({
            id: homeMenu.key,
            componentName: homeMenu.componentName,
            tabName: homeMenu.showName,
            closable : false
          })
          this.$data.activeModule = homeMenu.key
          //获取cookie
          this.$data.nickName = this.getItem("_nickName")

          const interval = setInterval(()=>{
            this.checkIsLogin(token).then((response)=>{
              if( !(response.data && response.data === true )){
                clearInterval(interval)
                _this.quitApp()
              }
            })
          },60000)

        }else {
          //跳转到登录页
          this.router.push("/login")
        }
      },(error => {
        //跳转到登录页
        console.log(error)
        this.router.push("/login")
      }));
    }else{
      //跳转到登录页
      this.router.push("/login")
    }

  },
  methods: {
    getCssVarName(type) {
      return `--el-box-shadow${type ? '-' : ''}${type}`
    },
    findModuleForName(moduleName){
      let targetModule
      this.$data.menu.forEach((x)=>{
        if(x.componentName === moduleName){
          targetModule = x
          return
        }
        if(x.subs && x.subs.length > 0){
          x.subs.forEach((sub)=>{
            if(sub.componentName === moduleName){
              targetModule = sub
            }
          })
        }
      })
      return targetModule
    },
    goToModule(moduleName){
      console.log("handle goto page event,goto module:" + moduleName)
      const targetModule = this.findModuleForName(moduleName)
      if(targetModule){
        this.openPage(targetModule)
      }
    },
    openPage(_component){
      let isExist = false
      this.$data.pages.forEach((tab) => {
        if(_component.key === tab.id){
          this.$data.activeModule = _component.key
          isExist = true
        }
      })
      if(!isExist){
        this.$data.pages.push({
          id: _component.key,
          componentName: _component.componentName,
          tabName: _component.showName,
          closable : true
        })
        this.$data.activeModule = _component.key
      }
      window.location.href = window.location.href.split("#")[0]+"#"+_component.componentName
    },
    removeTab(e){
      let activeModule = this.$data.activeModule
      let pages = this.$data.pages
      if (activeModule === e) {
        pages.forEach((tab, index) => {
          if (tab.id === e) {
            const nextTab = pages[index + 1] || pages[index - 1]
            if (nextTab) {
              activeModule = nextTab.id
            }
          }
        })
      }
      this.$data.activeModule = activeModule
      this.$data.pages = pages.filter((tab) => tab.id !== e)
    },
    quitApp(){
      const token = this.getItem("_token")
      if(token && token.length > 0){
        this.doLogout(token).then((response =>{
          console.log(response)
          this.router.push("/login")
        },error=>{
          console.log(error)
          this.router.push("/login")
        }));
      }else{
        this.router.push("/login")
      }
    },
    openUserSettings(){
      this.userSettings.openDetail()
    },
    filterMenu(subMenus,roles){
      let newMenu = []

      for(let item of subMenus){
        const subs = item.subs
        let subMenuFiltered;
        if(subs){
          subMenuFiltered = this.filterMenu(subs,roles)
        }
        if(subMenuFiltered){
          item.subs = subMenuFiltered
          newMenu.push(item)
        }else{
          Reflect.deleteProperty(item,"item")
          if(item.roles){
            for(let itemRole of item.roles){
              if(roles.includes(itemRole)){
                newMenu.push(item)
                break
              }
            }
          }
        }
      }

      if(newMenu.length ===0){
        return
      }else{
        return newMenu
      }
    }
  },
  computed:{
    menuRoleFilter(){
      //获取登录用户角色
      const roleStr = this.getItem("_roles")
      if(roleStr && roleStr != ""){
        const roles = JSON.parse(roleStr)
        const newMenu = this.filterMenu(this.$data.menu,roles)
        return newMenu
      }else{
        return []
      }
    }
  }
}

</script>

<style>
html, body, #app, #main-container {
  margin: 0;
  padding: 0;
  height: 100%;
}

.logo-pan {
  width: 148px;
  height: 32px;
  margin-top: 12px;
}

.flex-grow {
  flex-grow: 1;
}

.top-bar {
  border-bottom-color: transparent !important;
  height: 56px;
}

#main-menu {
  border-right:0 !important;
}

.module-tabs {
  border:0px solid transparent !important;
  --el-tabs-header-height: 30px !important;
}
.module-tabs .el-tabs__item{
  font-size: var(--el-font-size-extra-small) !important;
}
.module-tabs .el-tabs__item:hover{
  color: #00CA7A !important;
}
.module-tabs>.el-tabs__header .el-tabs__item{
  border-top-width: 1px !important;
  margin-top: 0px !important;
}
.module-tabs>.el-tabs__header .el-tabs__item.is-active{
  border-top-color: var(--el-border-color) !important;
  color: #00CA7A !important;
}
.module-tabs .el-tabs__content,.module-container-pan {
  background-color: #ececec;
}

:root {
  --el-color-primary: #00CA7A !important;
  --el-button-hover-bg-color: #00e88b !important;
  --el-color-primary-light-1: #00e88b !important;
  --el-color-primary-light-2: #00e88b !important;
  --el-color-primary-light-3: #00e88b !important;
  --el-color-primary-light-4: #00e88b !important;
  --el-color-primary-light-5: #00e88b !important;
  --el-color-primary-dark-1: #00e88b !important;
  --el-color-primary-dark-2: #00e88b !important;
  --el-color-primary-dark-3: #00e88b !important;
  --el-color-primary-dark-4: #00e88b !important;
  --el-color-primary-dark-5: #00e88b !important;
  --el-font-size-base: 12px !important;
  --el-font-size-extra-large: 18px !important;
  --el-font-size-large: 16px !important;
  --el-font-size-medium: 14px !important;
  --el-input-border-radius: 0 !important;
  --el-border-radius-base: 0 !important;
}
.detail-draw-pan .el-drawer__body{
  background-color: #ececec;
  padding:3px 12px;
}
.akz-pan {
  transition:box-shadow 0.5s ease-out;
  margin-top:10px;
  background-color: white;
}
.akz-pan:hover{
  transition:box-shadow 0.5s ease-out;
  box-shadow: var(--el-box-shadow-light);
}
.akz-pan > .akz-pan-title{
  padding:20px;
  font-size: var(--el-font-size-base);
  font-weight: 400;
  color: #7F7F7F;
  border-bottom: 1px solid #F2F2F2;
}
.akz-pan > .akz-pan-content{
  padding:20px;
}
.detail-draw-pan .el-drawer__header{
  margin-bottom: 20px;
}
.detail-draw-pan .el-drawer__header h4 svg{
  vertical-align: sub !important;
}

.detail-draw-pan .akz-pan {
  transition:box-shadow 0.5s ease-out;
  margin-top:10px;
  background-color: white;
}
.detail-draw-pan .akz-pan:hover {
  transition:box-shadow 0.5s ease-out;
  box-shadow: var(--el-box-shadow-light);
}
.detail-draw-pan .akz-pan > .akz-pan-title{
  padding:20px;
  font-size: var(--el-font-size-medium);
  font-weight: 700;
  color: #000000;
  border-bottom-color:transparent !important;
}
.detail-draw-pan .akz-pan > .akz-pan-content{
  font-size: 12px;
  padding: 0 20px 20px 20px;
}
.detail-draw-pan .akz-pan > .akz-pan-content .field-name{
  color: #A4A4A4;
}
.detail-draw-pan .akz-pan > .akz-pan-content .el-row{
  margin-bottom:12px;
}
.detail-draw-pan .akz-pan > .akz-pan-content button{
  vertical-align: baseline;
  margin-left:10px;
}
.detail-draw-pan .el-drawer__body{
  padding-bottom: 50px;
}
.detail-draw-pan .akz-pan > .akz-pan-content .el-table__body .el-table__row:last-child .el-table__cell{
  border-bottom-color: transparent !important;
}
.detail-draw-pan .akz-pan .el-table--border .el-table__inner-wrapper::after,.detail-draw-pan .akz-pan .el-table--border::after,.detail-draw-pan .akz-pan .el-table--border::before,.detail-draw-pan .akz-pan .el-table__inner-wrapper::before{
  background-color: transparent !important;
}

.akz-no-border-tabs .el-tabs__nav-scroll {
  background-color: white !important;
}
.akz-no-border-tabs .el-drawer__body{
  padding:10px;
  margin:0;
  background-color: white !important;
}
.akz-no-border-tabs .el-tabs__item{
  padding-top:0;
}
.akz-no-border-tabs .el-tabs__nav-wrap::after{
/*  background-color: transparent;*/
}
.akz-no-border-tabs .el-tabs__content{
  background-color: white;
}
.akz-popup > .akz-pan-content{
  font-size: 12px;
  padding: 0 20px 20px 20px;
}
.akz-popup .el-form-item__label{
  color: #A4A4A4;
}
.akz-popup .el-row{
  font-size: var(--el-font-size-base);
  margin-bottom:12px;
}
.table-multi-btns {
  font-size: var(--el-font-size-base);
  padding: 10px 17px 0 17px;
  background-color: #ffffff;
}
.loading-icon{
  position: absolute;
  width: 20px;
  height: 20px;
  z-index: 999;
  right:6px;top:61px;
}
.akz-tag {
  padding: 2px 4px;
  white-space: nowrap;
}
.akz-tag.success{
  background-color: #00CA7A;
  color: #ffffff;
}
.akz-tag.primary{
  background-color: #409EFF;
  color: #ffffff;
}
.akz-tag.warning{
  background-color: #E6A23C;
  color: #ffffff;
}
.akz-tag.danger{
  background-color: #F56C6C;
  color: #ffffff;
}
.akz-tag.info{
  background-color: #909399;
  color: #ffffff;
}
</style>