import request from "@/components/util/http";
import Storage from "@/components/util/Storage";

export default {
    mixins:[ Storage ],
    methods:{
        apiList(filters,pageSize,pageIndex){
            const supplierId = this.getItem("_supplierId")
            if(supplierId && supplierId.length < 1){
                return
            }
            return request({
                url : '/api/supplier/supplier/supplier-sign/list/' + supplierId + '/' + pageSize + '/' + pageIndex,
                method: 'get',
                params: filters
            })
        },
        apiListForType(){
            let filters = {}
            const supplierId = this.getItem("_supplierId")
            if(supplierId && supplierId.length < 1){
                return
            }
            return request({
                url : '/api/supplier/supplier/supplier-sign/sign-up-types/' + supplierId,
                method: 'get',
                params: filters
            })
        },
        apiOrderDetail(sOrderNo){
            const supplierId = this.getItem("_supplierId")
            if(supplierId && supplierId.length < 1){
                return
            }
            return request({
                url : '/api/supplier/supplier/supplier-sign/'+supplierId+'/'+ sOrderNo,
                method: 'get'
            })
        },
        apiUpdateSignComment(sOrderNo,comment){
            const supplierId = this.getItem("_supplierId")
            if(supplierId && supplierId.length < 1){
                return
            }
            return request({
                url : '/api/supplier/supplier/supplier-sign/'+supplierId+"/"+ sOrderNo +"/comment",
                method: 'put',
                data:{
                    comment: comment
                }
            })
        },
        apiReturnOrder(sOrderNo,comment){
            const supplierId = this.getItem("_supplierId")
            if(supplierId && supplierId.length < 1){
                return
            }
            return request({
                url : '/api/supplier/supplier/supplier-sign/operate/'+supplierId+"/REFUSE",
                method: 'post',
                data:{
                    orderNos: sOrderNo,
                    comment: comment
                }
            })
        },
        apiConfirmOrder(sOrderNos){
            const supplierId = this.getItem("_supplierId")
            if(supplierId && supplierId.length < 1){
                return
            }
            return request({
                url : '/api/supplier/supplier/supplier-sign/operate/'+supplierId+"/CONFIRM",
                method: 'post',
                data:{
                    orderNos: sOrderNos
                }
            })
        },
        apiDownloadOrders(sOrderNos){
            const supplierId = this.getItem("_supplierId")
            if(supplierId && supplierId.length < 1){
                return
            }
            return request({
                url: '/api/shopping/supplier/sign-up-order-detail/download/'+supplierId,
                method:'post',
                data:{
                    orderNos : sOrderNos
                },
                responseType: 'blob'
            })
        },
        apiUpdatePersonInfo(sSaleOrderNo,params){
            const supplierId = this.getItem("_supplierId")
            if(supplierId && supplierId.length < 1){
                return
            }
            return request({
                url : '/api/shopping/supplier/order-training/' + supplierId + "/" + sSaleOrderNo + "/person",
                method: 'put',
                data: params
            })
        },
        apiUpdateCompanyInfo(sSaleOrderNo,params){
            const supplierId = this.getItem("_supplierId")
            if(supplierId && supplierId.length < 1){
                return
            }
            return request({
                url : '/api/shopping/supplier/order-training/' + supplierId + "/" + sSaleOrderNo + "/company",
                method: 'put',
                data: params
            })
        },
        apiUpdatePostInfo(sSaleOrderNo,params){
            const supplierId = this.getItem("_supplierId")
            if(supplierId && supplierId.length < 1){
                return
            }
            return request({
                url : '/api/shopping/supplier/order-training/' + supplierId + "/" + sSaleOrderNo + "/post",
                method: 'put',
                data: params
            })
        },
    }
}