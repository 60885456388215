<template>
  <ConditionFilter ref="conditionFilter" :filterConfig="filterConfig" @doQuery="doQuery" :show-date-ranger="false"
                   :do-query-when-start="true"
                   :close-after-query="true"></ConditionFilter>
  <div class="feature-pan">
    <el-button type="primary" @click="openNew">新增</el-button>
  </div>
  <div v-loading="loading">
    <div class="table">
      <el-table
          :data="listData"
          style="width: 100%"
          @selection-change="handleSelectionChange">
        <el-table-column type="selection" width="50" align="center">
        </el-table-column>
        <el-table-column prop="userName" width="200" label="帐号" align="center">
          <template #default="scope">
            {{ scope.row.userName }}
            <span v-if="scope.row.handleManager" class="supplier-manager-tag">管理员</span>
          </template>
        </el-table-column>
        <el-table-column prop="nickName" width="150" label="姓名" align="center">
        </el-table-column>
        <el-table-column prop="description" label="备注" align="left" header-align="left">
        </el-table-column>
        <el-table-column prop="__supplierStatus" label="状态" width="50" align="center">
        </el-table-column>
        <el-table-column prop="__createTime" label="创建时间" width="180" align="center">
        </el-table-column>
        <el-table-column label="操作" width="180" align="left">
          <template #default="scope">
            <el-popconfirm v-if="scope.row.supplierStatus==='ENABLE' && scope.row.handleManager !== true"
                           confirm-button-text="确定"
                           cancel-button-text="取消"
                           title="确认禁用?"
                           @confirm="doUpdateStatus(scope.row.id,'DISABLE')">
              <template #reference>
                <el-button link class="akz-link">禁用</el-button>
              </template>
            </el-popconfirm>
            <el-popconfirm v-else-if="scope.row.supplierStatus==='DISABLE' && scope.row.handleManager !== true"
                           confirm-button-text="确定"
                           cancel-button-text="取消"
                           title="确认启用?"
                           @confirm="doUpdateStatus(scope.row.id,'ENABLE')">
              <template #reference>
                <el-button link class="akz-link">启用</el-button>
              </template>
            </el-popconfirm>
            <el-button link class="akz-link" @click="edit(scope.row.id)">编辑</el-button>
            <el-button link class="akz-link" @click="openResetPwd(scope.row.id)">重置密码</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="table-multi-btns">
      <span>批量操作：</span>
      <el-popconfirm confirm-button-text="确定"
                     cancel-button-text="取消"
                     title="确认批量启用?"
                     @confirm="updateStatusBatch('ENABLE')">
        <template #reference>
          <el-button link class="akz-link">启用</el-button>
        </template>
      </el-popconfirm>
      <el-popconfirm confirm-button-text="确定"
                     cancel-button-text="取消"
                     title="确认批量禁用?"
                     @confirm="updateStatusBatch('DISABLE')">
        <template #reference>
          <el-button link class="akz-link">禁用</el-button>
        </template>
      </el-popconfirm>
    </div>
    <div class="pagination-item">
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="record.currentIndex"
          :page-sizes="[20, 50, 100]"
          :page-size="record.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="record.total"></el-pagination>
    </div>
  </div>
  <el-drawer v-model="detail.show" :show-close="true" custom-class="detail-draw-pan" size="70%">
    <template #header="{titleId, titleClass }">
      <h4 :id="titleId" :class="titleClass" style="color: #000;font-weight: 650;font-size: 14px;">
        <Avatar style="width: 16px; height: 16px;" />
        <span style="margin-left: 8px;">子帐号详情</span></h4>
    </template>
    <div class="akz-pan">
      <div class="akz-pan-title">
        基本信息
      </div>
      <div class="akz-pan-content">
        <el-row :gutter="10">
          <el-col :span="4" :class="'field-name'">
            帐号
          </el-col>
          <el-col :span="20">
            {{ detail.userName }}
          </el-col>
        </el-row>
        <el-row :gutter="10">
          <el-col :span="4" :class="'field-name'">
            姓名
          </el-col>
          <el-col :span="20">
            {{ detail.nickName }}
            <el-button link class="akz-link" @click="openNickNameUpdate(detail.id,detail.nickName)">变更</el-button>
          </el-col>
        </el-row>
        <el-row :gutter="10">
          <el-col :span="4" :class="'field-name'">
            创建时间
          </el-col>
          <el-col :span="20">
            {{ detail.__createTime }}
          </el-col>
        </el-row>
        <el-row :gutter="10">
          <el-col :span="4" :class="'field-name'">
            备注
          </el-col>
          <el-col :span="20">
            <span v-if="detail.description">
              {{ detail.description }}
            </span>
            <span v-else style="color:#c2c2c2">
              未备注
            </span>
            <el-button link class="akz-link" @click="openDescriptionUpdate(detail.id,detail.description)">编辑</el-button>
          </el-col>
        </el-row>
        <el-row :gutter="10">
          <el-col :span="4" :class="'field-name'">
            状态
          </el-col>
          <el-col :span="20">
            <span>{{ detail.__supplierStatus }}</span>
            <el-switch v-model="detail._status" @change="updateStatus" style="margin-left: 10px"/>
          </el-col>
        </el-row>
      </div>
    </div>
    <div class="akz-pan">
      <div class="akz-pan-title">
        帐号安全
      </div>
      <div class="akz-pan-content">
        <el-row :gutter="10">
          <el-col :span="4" :class="'field-name'">
            密码
          </el-col>
          <el-col :span="20">
            <el-button link class="akz-link" @click="openResetPwd(detail.id)">重置密码</el-button>
          </el-col>
        </el-row>
<!--        <el-row :gutter="10">
          <el-col :span="4" :class="'field-name'">
            微信
          </el-col>
          <el-col :span="20">
            <el-button link class="akz-link">换绑</el-button>
          </el-col>
        </el-row>
        <el-row :gutter="10">
          <el-col :span="4" :class="'field-name'">
            绑定手机
          </el-col>
          <el-col :span="20">
            <el-button link class="akz-link">换绑</el-button>
          </el-col>
        </el-row>-->
      </div>
    </div>
    <div class="akz-pan">
      <div class="akz-pan-title">
        帐号授权
      </div>
      <div class="akz-pan-content">
        <el-button type="primary" style="margin-left: 0" @click="openAddRole(detail.id)" v-if="detail.handleManager!==true">新增授权</el-button>
        <el-table
            :data="listDetailRoles"
            style="width: 100%"
            @selection-change="handleSelectionChange">
          <el-table-column prop="roleName" width="200" label="角色权限" align="left" header-align="left">
          </el-table-column>
          <el-table-column prop="description" label="说明" align="left" header-align="left">
          </el-table-column>
          <el-table-column label="操作" width="80" align="left">
            <template #default="scope">
              <el-popconfirm confirm-button-text="确定"
                             cancel-button-text="取消"
                             title="是否删除角色授权?"
                             @confirm="deleteRole(detail.id,scope.row.roleType)"
                             v-if="detail.handleManager!==true">
                <template #reference>
                  <el-button link class="akz-link">删除</el-button>
                </template>
              </el-popconfirm>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <div class="akz-pan" v-if="!detail.handleManager">
      <div class="akz-pan-content">
        <el-popconfirm confirm-button-text="确定"
                       cancel-button-text="取消"
                       title="删除后无法恢复，是否继续删除子帐号?"
                       @confirm="deleteUser(detail.id)">
          <template #reference>
            <el-button link class="akz-link" style="margin-top:20px">删除子帐号</el-button>
          </template>
        </el-popconfirm>
      </div>
    </div>
    <div class="akz-footer"></div>
  </el-drawer>
  <PopupForm :show-popup="newRecord.show" pop-title="新增子帐号" :confirm-action="doAddNew"
             @close-popup="closeNew" :form-config="newRecord.config"></PopupForm>
  <PopupForm :show-popup="resetPwd.show" pop-title="重置密码" :confirm-action="doResetPwd"
             @close-popup="closeResetPwd" :form-config="resetPwd.config"></PopupForm>
  <PopupForm :show-popup="updateNickName.show" pop-title="变更姓名" :confirm-action="doUpdateNickName"
             @close-popup="closeNickNameUpdate" :form-config="updateNickName.config" ref="nickNameUpdatePop"></PopupForm>
  <PopupForm :show-popup="updateComment.show" pop-title="备注" :confirm-action="doUpdateComment"
             @close-popup="closeCommentUpdate" :form-config="updateComment.config" ref="commentUpdatePop"></PopupForm>
  <PopupForm :show-popup="addRole.show" pop-title="新增授权" :confirm-action="doAddRole"
             @close-popup="closeAddRole" :form-config="addRole.config" ref="addRolePop"></PopupForm>
</template>

<script>
import dateFormat from "@/components/util/DateFormat";
import {ElMessage as Message} from 'element-plus'
import AccountApi from "@/api/AccountApi";
import {ref} from 'vue'

export default {
  name: "AccountManagement",
  emits: ["goto-module"],
  mixins: [dateFormat, AccountApi],
  setup() {
    const conditionFilter = ref(null)
    const nickNameUpdatePop = ref(null)
    const commentUpdatePop = ref(null)
    const addRolePop = ref(null)
    return {
      conditionFilter,nickNameUpdatePop,commentUpdatePop,addRolePop
    }
  },
  data() {
    return {
      loading: false,
      filterConfig: [
        {
          type: 'input',
          title: '帐号',
          placeHolder: '输入帐号关键字',
          bindField: 'userName',
          showInMain: true
        },
        {
          type: 'input',
          title: '姓名',
          placeHolder: '输入昵称关键字',
          bindField: 'nickName',
          showInMain: true
        },
        {
          type: 'select',
          title: '状态',
          placeHolder: '选择',
          bindField: 'supplierStatus',
          options: [
            {label: "启用", value: "ENABLE"},
            {label: "禁用", value: "DISABLE"}
          ],
          showInMain: true
        },
        {
          type: 'input',
          title: '备注',
          placeHolder: '输入备注关键字',
          bindField: 'description',
        }
      ],
      record: {
        list: [],
        currentIndex: 1,
        total: 100,
        pageSize: 20,
        selected: []
      },
      detail: {
        show: false,
      },
      roles: {},
      roleList: [],
      resetPwd: {
        show: ref(false),
        config: [
          {
            type: 'input-pwd',
            title: '新密码',
            bindField: 'pwd',
            placeHolder:'输入新密码，密码长度需大于6位，且包含大小写字母和[!@#$%^&*.,]字符中的任意一个',
            rules: [{
              required: true, message: '请输入足够安全的密码，密码长度需大于6位，且包含大小写字母和[!@#$%^&*.,]字符中的任意一个', trigger: 'blur',
              pattern: /^(?=.*[a-zA-Z])(?=.*[1-9])(?=.*[!|@|#|$|%|^|&|*|\\.|\\,]).{6,}$/
            }]
          }
        ]
      },
      addRole: {
        show: ref(false),
        config: [
          {
            type: 'select',
            title: '新增授权',
            bindField: 'role',
            options: this.$data.roleList
          }
        ]
      },
      updateNickName: {
        show: ref(false),
        id : -1,
        config: [
          {
            type: 'input',
            title: '姓名',
            bindField: 'nickName',
            defaultValue: "",
            rules: [{required: true, message: '请输入姓名', trigger: 'blur'}]
          }
        ]
      },
      updateComment: {
        show: ref(false),
        id: -1,
        config: [
          {
            type: 'area',
            title: '备注',
            bindField: 'description',
            rowCount:6,
            placeHolder:'输入备注信息(可为空)，长度小于100个字',
            rules: [{
              required: false,message: '长度不能超过100', trigger: 'blur',
              pattern: /^.{0,100}$/
            }]
          }
        ]
      },
      newRecord: {
        show: ref(false),
        config: [{
          type: 'input',
          title: '帐号',
          bindField: 'userName',
          rules: [{
            required: true,
            message: '请输入用户名，长度8-20位，只能包含大小写字母和数字',
            trigger: 'blur',
            pattern: /^[a-zA-Z0-9]{8,20}$/
          }]
        }, {
          type: 'input',
          title: '姓名',
          bindField: 'nickName',
          rules: [{required: true, message: '请输入姓名', trigger: 'blur'}]
        }, {
          type: 'input-pwd',
          title: '密码',
          bindField: 'pwd',
          rules: [{
            required: true, message: '请输入足够安全的密码，密码长度需大于6位，且包含大小写字母和[!@#$%^&*.,]字符中的任意一个', trigger: 'blur',
            pattern: /^(?=.*[a-zA-Z])(?=.*[1-9])(?=.*[!|@|#|$|%|^|&|*|\\.|\\,]).{6,}$/
          }]
        }, {
          type: 'area',
          title: '备注',
          placeHolder: '(非必填)',
          bindField: 'description',
          rowCount: 9
        }, {
          type: 'switch',
          title: '状态',
          bindField: 'supplierStatus',
          defaultValue: true,
          trueText: '启用',
          falseText: '禁用',
          rules: [{required: true, message: '请输入密码', trigger: 'blur'}]
        }]
      }
    }
  },
  methods: {
    doQuery(filters) {
      //获取分页信息
      const _currentIndex = this.$data.record.currentIndex
      const _pageSize = this.$data.record.pageSize
      this.$data.loading = true

      //获取页大小和页数
      this.apiList(filters, _pageSize, _currentIndex).then((response) => {
        this.$data.loading = false
        this.$data.record.total = response.data.total
        this.$data.record.list = response.data.data ? response.data.data : []
      })

    },
    search() {
      const filters = this.conditionFilter.getFilters()
      this.doQuery(filters)
    },
    handleSizeChange(pageSize) {
      this.record.pageSize = pageSize
      this.search()
    },
    handleCurrentChange(pageIndex) {
      this.record.currentIndex = pageIndex
      this.search()
    },
    doUpdateStatus(id, action) {
      const _this = this
      this.apiUpdateStatus([id], action)
          .then(function (response) {
            if (response.code === 0) {
              Message.success("操作成功")
              _this.search()
            }
          })
    },
    updateStatusBatch(action) {
      if (this.$data.record.selected.length === 0) {
        Message.info("请选择要操作的数据")
        return
      }
      const _this = this
      this.apiUpdateStatus(this.$data.record.selected, action).then(function (response) {
        if (response.code === 0) {
          Message.success("操作成功")
          _this.search()
        }
      })

      console.log("update status batch,action:" + action)
    },
    updateStatus(value){
      const id = this.$data.detail.id
      const _this = this
      this.apiUpdateStatus([id], value===true?"ENABLE":"DISABLE")
          .then(function (response) {
            if (response.code === 0) {
              Message.success("操作成功")

              _this.search()
            }
          })
    },
    handleSelectionChange(changed) {
      this.$data.record.selected = changed.map(x => x.id)
    },
    doResetPwd(data) {
      this.apiResetPwd(this.$data.resetPwd.id,data.pwd)
          .then(() => {
            Message.success("重置密码成功")
            this.closeResetPwd()
          })
          .catch(error => {
            Message.error(error.message)
          })
    },
    doUpdateNickName(data){
      this.apiUpdateNickName(this.$data.updateNickName.id,data.nickName).then(()=>{
        this.$data.detail.nickName = data.nickName
        this.closeNickNameUpdate()
        this.search()
      })
    },
    doUpdateComment(data){
      this.apiUpdateComment(this.$data.updateComment.id,data.description).then(()=>{
        this.$data.detail.description = data.description
        this.closeCommentUpdate()
        this.search()
      })
    },
    doAddRole(data){
      this.apiAddRole(this.$data.addRole.id,data.role).then(()=>{
        let isAddInList = false
        console.log("add role",123)
        if(this.$data.detail.roles){
          for (let role of this.$data.detail.roles){
            if(role === data.role){
              this.closeAddRole()
              return
            }
          }
          isAddInList = true
        }else{
          this.$data.detail.roles = []
          isAddInList = true
        }

        if(isAddInList === true){
          this.$data.detail.roles.push(data.role)
        }
        this.closeAddRole()
      })
    },
    openResetPwd(data) {
      this.$data.resetPwd.show = true
      this.$data.resetPwd.id = data
    },
    openNickNameUpdate(id,value) {
      this.$data.updateNickName.show = true
      this.$data.updateNickName.id = id
      this.nickNameUpdatePop.initialData({nickName: value})
    },
    openDescriptionUpdate(id,value) {
      this.$data.updateComment.show = true
      this.$data.updateComment.id = id
      this.commentUpdatePop.initialData({description: value})
    },
    openAddRole(id) {
      this.$data.addRole.show = true
      this.$data.addRole.id = id
    },
    closeNickNameUpdate(){
      this.$data.updateNickName.show = false
    },
    closeResetPwd(){
      this.$data.resetPwd.show = false
    },
    closeCommentUpdate(){
      this.$data.updateComment.show = false
    },
    closeAddRole(){
      this.$data.addRole.show = false
    },
    edit(id) {
      const filteredData = this.$data.record.list.filter((x) => x.id === id);
      if (filteredData.length > 0) {
        let detailData = JSON.parse(JSON.stringify(filteredData[0]));
        detailData.show = true
        detailData._status = detailData.supplierStatus === "ENABLE"
        this.$data.detail = detailData
      }
    },
    closeEdit(){
      this.$data.detail.show = false
    },
    openNew() {
      this.$data.newRecord.show = true
    },
    closeNew() {
      this.$data.newRecord.show = false
    },
    doAddNew(data) {
      this.apiAdd(data).then(() => {
        this.closeNew()
        this.search()
      }).catch(error => {
        console.log(error)
        Message.error(error.message)
      })
    },
    deleteUser(id){
      this.apiDelete(id).then(()=>{
        this.closeEdit()
        this.search()
      }).catch(error =>{
        console.log(error)
        Message.error(error.message)
      })
    },
    deleteRole(id,roleType){
      //检查用户是否管理员,如果用户是管理，则不能删除管理员权限
      if(this.$data.detail.handleManager === true && roleType === "MANAGER"){
        Message.error("管理员不能删除该角色")
        return
      }

      this.apiDeleteRole(id,roleType).then(()=>{
        let removeIndex = -1
        for (let i=0;i<this.$data.detail.roles.length;i++){
          if(this.$data.detail.roles[i] === roleType){
            removeIndex = i
          }
        }
        if(removeIndex != -1){
          this.$data.detail.roles.splice(removeIndex,1)
        }
        if(!this.$data.detail.roles){
          this.$data.detail.roles = []
        }
        this.search()
      })
    }
  },
  beforeMount() {
    this.apiRoles().then((response) => {
      if (typeof response.code !== "undefined" && response.code === 0) {
        const roleMap = {}
        if (response.data && response.data.length > 0) {
          response.data.forEach(x => {
            roleMap[x.roleType] = x
            this.$data.roleList.push({
              label: x.showName+" \\ "+x.description,
              value: x.roleType
            })
          })
        }
        console.log(this.$data.roleList,123)
        this.$data.addRole.config[0].options = this.$data.roleList
        this.$data.roles = roleMap
      }
    })
  },
  computed: {
    listData: function () {
      const statusEnums = {};
      this.$data.filterConfig[2].options.forEach(x => {
        statusEnums[x.value] = x.label
      })
      return this.$data.record.list.map((row) => {
        //处理时间显示
        row.__createTime = this.dateFormat(row.createTime)
        //处理status
        row.__supplierStatus = statusEnums[row.supplierStatus]
        return row
      })
    },
    listDetailRoles: function () {
      if (this.$data.detail.roles) {
        const _this = this
        return this.$data.detail.roles.map((row) => {
          const role = _this.$data.roles[row]
          return {
            roleName: role.showName,
            description: role.description,
            roleType: role.roleType,
            value: role.value
          }
        })
      }
      return []
    }
  }
}
</script>

<style scoped>
.feature-pan {
  padding-bottom: 10px;
}



.supplier-manager-tag {
  padding: 2px 4px;
  background-color: #00CA7A;
  color: #ffffff;
  white-space: nowrap;
}
</style>