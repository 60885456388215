import request from "@/components/util/http";
import Storage from "@/components/util/Storage";

export default {
    mixins:[ Storage ],
    methods:{
        apiMemberList(classId){
            const supplierId = this.getItem("_supplierId")
            if(supplierId && supplierId.length < 1){
                return
            }
            return request({
                url : '/api/supplier/supplier/train-class-member/'+ supplierId +'/' + classId + '/members',
                method: 'get'
            })
        },
        apiUpdateMemberClass(id,newClassId){
            const supplierId = this.getItem("_supplierId")
            if(supplierId && supplierId.length < 1){
                return
            }
            return request({
                url : '/api/supplier/supplier/train-class-member/train-class',
                method: 'put',
                data:{
                    newClassId: newClassId,
                    ids: id
                },
                params:{
                    supplierId: supplierId
                }
            })
        },
        apiAddMemberClass(sOrderNos,classId,description){
            const supplierId = this.getItem("_supplierId")
            if(supplierId && supplierId.length < 1){
                return
            }
            return request({
                url : '/api/supplier/supplier/train-class-member',
                method: 'post',
                data:{
                    orderNos: sOrderNos,
                    classId: classId,
                    description: description
                },
                params:{
                    supplierId: supplierId
                }
            })
        },
        //批量更新班级成员备注
        apiUpdateMemberDescription(classId,ids,description){
            const supplierId = this.getItem("_supplierId")
            if(supplierId && supplierId.length < 1){
                return
            }
            return request({
                url : '/api/supplier/supplier/train-class-member/'+supplierId+"/description",
                method: 'put',
                data:{
                    classId: classId,
                    ids: ids,
                    description: description
                }
            })
        }
    }
}