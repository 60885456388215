<template>
  <el-drawer v-model="show" :show-close="true" custom-class="detail-draw-pan" size="70%" :destroy-on-close="true" >
    <template #header="{titleId, titleClass }">
      <h4 :id="titleId" :class="titleClass" style="color: #000;font-weight: 650;font-size: 14px;">
        <Tickets style="width: 16px; height: 16px;"/>
        <span style="margin-left: 8px;">个人中心</span></h4>
    </template>
    <div v-loading="loading">
      <div class="akz-pan">
        <div class="akz-pan-title">
          个人信息
        </div>
        <div class="akz-pan-content">
          <el-row :gutter="10">
            <el-col :span="4" :class="'field-name'">
              姓名
            </el-col>
            <el-col :span="20">
              {{ detail.user.nickName }}
              <el-button link class="akz-link" @click="openNickNameUpdate">更改</el-button>
            </el-col>
          </el-row>
          <el-row :gutter="10">
            <el-col :span="4" :class="'field-name'">
              密码
            </el-col>
            <el-col :span="20">
              ******
              <el-button link class="akz-link" @click="openPwdUpdate">更改</el-button>
            </el-col>
          </el-row>
          <el-row :gutter="10">
            <el-col :span="4" :class="'field-name'">
              手机
            </el-col>
            <el-col :span="20">
              {{ detail.user.phone?detail.user.phone:"未绑定" }}
              <el-button link class="akz-link" @click="openPhoneBind">更改</el-button>
            </el-col>
          </el-row>
        </div>
      </div>
      <div class="akz-pan">
        <div class="akz-pan-title">
          消息接收配置
        </div>
        <div class="akz-pan-content" v-if="showOrderSubscribeConfig">
          <el-row :gutter="10">
            <el-col :span="4" :class="'field-name'">
              新订单推送
            </el-col>
            <el-col :span="20">
              <el-switch v-model="detail.message.orderSubmitted.get" @change="doUpdateMsgSubscribeData" />
            </el-col>
          </el-row>
          <el-row :gutter="10">
            <el-col :span="4" :class="'field-name'">
            </el-col>
            <el-col :span="20">
              <el-radio-group v-model="detail.message.orderSubmitted.ignoreType" @change="doUpdateMsgSubscribeData">
                <el-radio-button label="NONE" >任何时间</el-radio-button>
                <el-radio-button label="WORK_TIME" >工作时间(8:30-17:30)</el-radio-button>
              </el-radio-group>
            </el-col>
          </el-row>
        </div>
      </div>
    </div>
  </el-drawer>


  <PopupForm :show-popup="updateNickName.show" pop-title="变更姓名" :confirm-action="doUpdateNickName"
             @close-popup="closeNickNameUpdate" :form-config="updateNickName.config" ref="nickNameUpdatePop"></PopupForm>
  <PopupForm :show-popup="updatePwd.show" pop-title="变更密码" :confirm-action="doUpdatePwd"
             @close-popup="closePwdUpdate" :form-config="updatePwd.config" ref="pwdUpdatePop"></PopupForm>
  <UserBindPhone ref="userBindPhoneRef" @close-popup="getUserInfo" />
</template>

<script>
import Storage from "@/components/util/Storage";
import MessageSubscribeConfigApi from "@/api/MessageSubscribeConfigApi";
import {ElMessage as Message} from "element-plus"
import {ref} from "vue";
import AccountApi from "@/api/AccountApi";
import UserBindPhone from "@/components/user/UserBindPhone";

export default {
  name: "UserSettings",
  mixins:[ Storage,MessageSubscribeConfigApi,AccountApi ],
  components:{ UserBindPhone },
  setup(){
    const nickNameUpdatePop = ref(null)
    const pwdUpdatePop = ref(null)
    const userBindPhoneRef = ref(null)
    return{
      nickNameUpdatePop,pwdUpdatePop,userBindPhoneRef
    }
  },
  data(){
    return {
      show: false,
      loading: false,
      detail:{
        user:{
          nickName:"",
          phone:""
        },
        message:{
          orderSubmitted:{
            get: false,
            ignoreType:""
          }
        }
      },
      updateNickName: {
        show: ref(false),
        id : -1,
        config: [
          {
            type: 'input',
            title: '姓名',
            bindField: 'nickName',
            defaultValue: "",
            rules: [{required: true, message: '请输入姓名', trigger: 'blur'}]
          }
        ]
      },
      updatePwd: {
        show: ref(false),
        id : -1,
        config: [
          {
            type: 'input-pwd',
            title: '旧密码',
            bindField: 'oldPwd',
            defaultValue: "",
            rules: [{required: true, message: '请输入旧密码', trigger: 'blur'}]
          },
          {
            type: 'input-pwd',
            title: '新密码',
            bindField: 'newPwd',
            defaultValue: "",
            rules: [{required: true, message: '请输入新密码', trigger: 'blur'},{
              required: true, message: '请输入足够安全的密码，密码长度需大于6位，且包含大小写字母和[!@#$%^&*.,]字符中的任意一个', trigger: 'blur',
              pattern: /^(?=.*[a-zA-Z])(?=.*[1-9])(?=.*[!|@|#|$|%|^|&|*|\\.|\\,]).{6,}$/
            }]
          }
        ]
      }
    }
  },
  methods:{
    openDetail(){
      this.$data.show = true
      this.$data.detail.user.nickName = this.getItem("_nickName")

      this.getMsgSubscribe()
      this.getUserInfo()
    },
    getMsgSubscribe(){
      this.$data.loading = true
      this.apiGetOperatorMsgSubscribeConfig().then((res)=>{
        if(res.data){
          for (let config of res.data) {
            if(config.subscribeMsgType === "ORDER_SUBMITTED"){
              this.$data.detail.message.orderSubmitted={
                get: true,
                ignoreType:config.noneWorktimeDisturb
              }
            }
          }
        }
        this.$data.loading = false
      },(error)=>{
        Message.error("获取消息订阅数据错误:"+error.message)
        this.resetMsgSubscribeData()
        this.$data.loading = false
      })
    },
    getUserInfo(){
      this.apiGetOperatorInfo().then((res)=>{
        this.$data.detail.user.phone = res.data.phone
      })
    },
    resetMsgSubscribeData(){
      this.$data.detail.message.orderSubmitted = {
        get: false,
        ignoreType:""
      }
    },
    doUpdateMsgSubscribeData(){
      const subscribeType = "ORDER_SUBMITTED"
      let {get,ignoreType} = this.$data.detail.message.orderSubmitted

      if(get === true){
        if(this.$data.detail.user.phone === ""){
          this.openPhoneBindDialog()
        }

        if(ignoreType === ""){
          this.$data.detail.message.orderSubmitted.ignoreType = "NONE"
          ignoreType = "NONE"
        }

        this.apiAddOrUpdateMsgSubscribeConfig(subscribeType,ignoreType).then(()=>{
          this.getMsgSubscribe()
        },)
      }else if(get === false){
        this.apiRemoveMsgSubscribeConfig(subscribeType).then(()=>{
          this.getMsgSubscribe()
        })
      }
    },
    openPhoneBindDialog(){
        Message({
          dangerouslyUseHTMLString: true,
          message: '<div>需要在<strong> 个人中心 > 绑定手机 </strong>后才能正常推送你订阅的消息</div>',
          type: 'warning',
          duration: 0,
          showClose: true
        })
    },
    /* 更新昵称 */
    openNickNameUpdate() {
      this.$data.updateNickName.show = true
      this.nickNameUpdatePop.initialData({nickName: this.$data.detail.user.nickName})
    },
    closeNickNameUpdate(){
      this.$data.updateNickName.show = false
    },
    doUpdateNickName(data){
      this.$data.loading = true
      this.apiUpdateOperatorNickName(data.nickName).then(()=>{
        this.$data.detail.user.nickName = data.nickName
        this.closeNickNameUpdate()
        this.setItem("_nickName",data.nickName)
        this.$data.loading = false
      },(error)=>{
        this.$data.loading = false
        Message.error(error.message)
      })
    },
    /* 更新昵称 */
    openPwdUpdate() {
      this.$data.updatePwd.show = true
    },
    closePwdUpdate(){
      this.$data.updatePwd.show = false
      this.pwdUpdatePop.resetForm()
    },
    doUpdatePwd(data){
      this.apiUpdateOperatorPwd(data.oldPwd,data.newPwd).then(()=>{
        Message.success("密码更新成功")
        this.closePwdUpdate()
      },(error)=>{
        Message.error(error.message)
      })
    },
    /* 绑定手机 */
    openPhoneBind(){
      this.userBindPhoneRef.openDetail()
    }
  },
  computed:{
    showOrderSubscribeConfig(){
      const roleStr = this.getItem("_roles")
      if(roleStr && roleStr != ""){
        const roles = JSON.parse(roleStr)
        return roles.includes("SIGN_MANAGER") || roles.includes("MANAGER")
      }else{
        return false
      }
    }
  }
}
</script>

<style scoped>

</style>