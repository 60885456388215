<template>
  <el-row :gutter="10" v-loading="loading">
    <el-col :span="6"><SummaryCard :bg-color="'#02a7f0'" :title="'待处理报名'" :forward-module="'SignMng'" @gotoModule="goToModule">{{ thousandSplit(signNo) }}</SummaryCard></el-col>
    <el-col :span="6"><SummaryCard :bg-color="'#25c6a4'" :title="'待开发票订单'" :forward-module="'BillOutMng'" @gotoModule="goToModule">{{ thousandSplit(billToMakeOut) }}</SummaryCard></el-col>
    <el-col :span="6"><SummaryCard :bg-color="'#00bcca'" :title="'今日订单数'">{{ thousandSplit(orderToday) }}</SummaryCard></el-col>
    <el-col :span="6"><SummaryCard :bg-color="'#053659'" :title="'本月累计订单数'">{{ thousandSplit(orderThisMonth) }}</SummaryCard></el-col>
  </el-row>

  <el-row :gutter="10">
    <el-col :span="24">
      <div class="akz-pan">
        <div class="akz-pan-title">
          销售量趋势分析
        </div>
        <div id="sale-history" class="akz-pan-content" style="height: 280px;">
        </div>
      </div>
    </el-col>
  </el-row>
</template>

<script>
import SummaryCard from "@/components/home/SummaryCard";
import NumFormat from "@/components/util/NumFormat";
import * as echarts from 'echarts';
import StatisticApi from "@/api/StatisticApi";

export default {
  name: "ProHome",
  components:{SummaryCard},
  mixins: [NumFormat,StatisticApi],
  emits:["goto-module"],
  data(){
    return {
      signNo: 0,
      billToMakeOut: 0,
      orderToday: 0,
      orderThisMonth: 0,
      loading: true
    }
  },
  methods:{
    goToModule(e){
      this.$emit("goto-module",e)
    }
  },
  mounted() {
    //获取统计信息
    this.apiHomeStatistic().then((response)=>{
      if(response.data){
        this.$data.signNo = response.data.unHandleSignUp
        this.$data.billToMakeOut = response.data.unHandleBillOut
        this.$data.orderToday = response.data.ordersToday
        this.$data.orderThisMonth = response.data.ordersMonth
        this.$data.loading = false
      }
    })
    //初始化echarts
    this.apiHomeOrderCountsIn48Hours().then((response)=>{
      if(response.data){
        const _today = response.data.today
        const _yesterday = response.data.yesterday

        // 基于准备好的dom，初始化echarts实例
        const myChart = echarts.init(document.getElementById('sale-history'),null,{
          height: 280
        });
        const _width = window.innerWidth-250
        myChart.resize({
          width: _width
        });
        window.onresize = function() {
          const _width = window.innerWidth-250
          myChart.resize({
            width: _width
          });
        };
        const option = {
          color:["#00CA7A","#cacaca"],
          legend: {
            data: ['今天', '昨天'],
            bottom: 10
          },
          tooltip: {
            trigger: 'axis'
          },
          xAxis: {
            type: 'category',
            data: ['00:00','01:00', '02:00', '03:00', '04:00', '05:00', '06:00', '07:00','08:00','09:00','10:00',
              '11:00','12:00','13:00','14:00','15:00','16:00','17:00','18:00','19:00','20:00','21:00',
              '22:00','23:00'],
            axisLine:{
              lineStyle:{
                color:'#cacaca'
              }
            }
          },
          yAxis: {
            type: 'value',
            boundaryGap: true,
            splitNumber:3,
            axisLabel:{
              color: '#cacaca'
            }
          },
          grid: {
            left: '3%',
            right: '4%',
            bottom: '50',
            top:'10',
            containLabel: true
          },
          series: [
            {
              name: '今天',
              data: _today,
              type: 'line'
            },
            {
              name: '昨天',
              data: _yesterday,
              type: 'line'
            }
          ]
        };
        // 绘制图表
        myChart.setOption(option);
      }
    })

  },
  computed:{

  }
}
</script>

<style scoped>

</style>