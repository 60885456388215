<template>
  <!--  详情  -->
  <el-drawer v-model="show" :show-close="true" custom-class="detail-draw-pan" size="70%" :destroy-on-close="true"
             @close="closeDetail">
    <template #header="{titleId, titleClass }">
      <h4 :id="titleId" :class="titleClass" style="color: #000;font-weight: 650;font-size: 14px;">
        <Tickets style="width: 16px; height: 16px;"/>
        <span style="margin-left: 8px;">报名详情</span></h4>
    </template>
    <div v-loading="loading">
      <div v-if="order.orderStatus==='ENABLE'" class="akz-pan" style="padding:15px;">
        <el-popconfirm v-if="order.signUpStatus==='UN_SIGN'" confirm-button-text="报名OK，执行确定"
                       cancel-button-text="取消"
                       title="报名订单确认后无法退回，是否继续?"
                       @confirm="confirmOrder">
          <template #reference>
            <el-button link type="default">确认报名</el-button>
          </template>
        </el-popconfirm>
        <el-button v-if="order.signUpStatus==='SIGNED'" type="default" style="margin-left: 0" @click="openClassChoose">分配班级</el-button>
        <el-popconfirm v-if="order.signUpStatus==='UN_SIGN'" confirm-button-text="确定退回"
                       cancel-button-text="取消"
                       title="是否确认退回订单?"
                       @confirm="openRefuseDialog(order.orderNo)">
          <template #reference>
            <el-button link type="default">退回</el-button>
          </template>
        </el-popconfirm>
      </div>
      <div v-if="orderRefused" class="akz-pan order-refused">
        已退单
      </div>

      <div class="akz-pan">
        <div class="akz-pan-title">
          订单信息
        </div>
        <div class="akz-pan-content">
          <el-row :gutter="10">
            <el-col :span="4" :class="'field-name'">
              订单号
            </el-col>
            <el-col :span="20">
              {{ order.orderNo }}
            </el-col>
          </el-row>
          <el-row :gutter="10">
            <el-col :span="4" :class="'field-name'">
              报名时间
            </el-col>
            <el-col :span="20">
              {{ dealTime }}
            </el-col>
          </el-row>
          <el-row :gutter="10">
            <el-col :span="4" :class="'field-name'">
              地区\报名类型\科目
            </el-col>
            <el-col :span="20">
              {{ order.seriesName }} / {{ order.productName }}
            </el-col>
          </el-row>
          <el-row :gutter="10">
            <el-col :span="4" :class="'field-name'">
              订单说明
            </el-col>
            <el-col :span="20">
              {{ order.description }}
            </el-col>
          </el-row>
          <el-row :gutter="10">
            <el-col :span="4" :class="'field-name'">
              备注
            </el-col>
            <el-col :span="20">
              <span v-if="order.signUpComment" style="word-break: break-all">
                {{ order.signUpComment }}
              </span>
              <span v-else style="color:#c2c2c2">
                未备注
              </span>
              <el-button link class="akz-link" @click="openUpdateDescription">编辑</el-button>
            </el-col>
          </el-row>
        </div>
      </div>
      <div class="akz-pan">
        <div class="akz-pan-title">
          个人信息
          <el-button link class="akz-link" @click="openPersonInfoUpdate">编辑</el-button>
        </div>
        <div class="akz-pan-content">
          <el-row :gutter="10">
            <el-col :span="4" :class="'field-name'">
              姓名
            </el-col>
            <el-col :span="20">
              {{ person.customerName }}
            </el-col>
          </el-row>
          <el-row :gutter="10">
            <el-col :span="4" :class="'field-name'">
              联系电话
            </el-col>
            <el-col :span="20">
              {{ person.phone }}
            </el-col>
          </el-row>
          <el-row :gutter="10">
            <el-col :span="4" :class="'field-name'">
              身份证号
            </el-col>
            <el-col :span="20">
              {{ person.idCardNo }}
            </el-col>
          </el-row>
          <el-row :gutter="10">
            <el-col :span="4" :class="'field-name'">
              身份证
            </el-col>
            <el-col :span="20">
              <div class="id-card-img">
                <div class="id-card-name">身份证正面</div>
                <el-upload
                    class="upload-demo"
                    :action="updateCardFrontPath"
                    :auto-upload="true"
                    name="fileName"
                    @success="cardFrontUpdateSuccess"
                >
                  <template #trigger>
                    <el-image :src="idCardFront" loading="eager">
                      <template #placeholder>
                        <div class="image-slot">加载中<span class="dot">...</span></div>
                      </template>
                      <template #error>
                        <div class="image-slot">
                          <el-icon>
                            <UploadFilled/>
                          </el-icon>
                          <div class="id-card-img-des">点击上传</div>
                        </div>
                      </template>
                    </el-image>
                  </template>
                  <template #tip>
                    <div class="el-upload__tip">
                      *选择小于500kb的jpg/png文件进行上传
                    </div>
                  </template>
                </el-upload>

              </div>
              <div class="id-card-img">
                <div class="id-card-name">身份证反面</div>
                <el-upload
                    class="upload-demo"
                    :action="updateCardBackPath"
                    :auto-upload="true"
                    name="fileName"
                    @success="cardBackUpdateSuccess"
                >
                  <template #trigger>
                    <el-image :src="idCardBack" loading="eager">
                      <template #placeholder>
                        <div class="image-slot">加载中<span class="dot">...</span></div>
                      </template>
                      <template #error>
                        <div class="image-slot">
                          <el-icon>
                            <UploadFilled/>
                          </el-icon>
                          <div class="id-card-img-des">点击上传</div>
                        </div>
                      </template>
                    </el-image>
                  </template>
                  <template #tip>
                    <div class="el-upload__tip">
                      *选择小于500kb的jpg/png文件进行上传
                    </div>
                  </template>
                </el-upload>
              </div>
            </el-col>
          </el-row>
          <el-row :gutter="10">
            <el-col :span="4" :class="'field-name'">
              证件照
            </el-col>
            <el-col :span="20">
              <div class="id-card-img id-photo">
                <el-upload
                    class="upload-demo"
                    :action="updateIDPhotoPath"
                    :auto-upload="true"
                    name="fileName"
                    @success="idPhotoUpdateSuccess"
                    :before-upload="beforePickUpdate" >
                  <template #trigger>
                    <el-image :src="idPhoto" loading="eager">
                      <template #placeholder>
                        <div class="image-slot">加载中<span class="dot">...</span></div>
                      </template>
                      <template #error>
                        <div class="image-slot">
                          <el-icon>
                            <UploadFilled/>
                          </el-icon>
                          <div class="id-card-img-des">点击上传</div>
                        </div>
                      </template>
                    </el-image>
                  </template>
                  <template #tip>
                    <div class="el-upload__tip">
                      *选择小于500kb的jpg/png文件进行上传
                    </div>
                  </template>
                </el-upload>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
      <div class="akz-pan">
        <div class="akz-pan-title">
          单位信息
          <el-button link class="akz-link" @click="openCompanyInfoUpdate">编辑</el-button>
        </div>
        <div class="akz-pan-content">
          <el-row :gutter="10">
            <el-col :span="4" :class="'field-name'">
              开票状态
            </el-col>
            <el-col :span="20">
              <span v-if="company.billOutStatus==='AWAIT'" class="akz-tag warning">待开票</span>
              <span v-if="company.billOutStatus==='DONE'" class="akz-tag success">已开票</span>
              <span v-if="company.billOutStatus==='IGNORE'" class="akz-tag info">已忽略</span>
            </el-col>
          </el-row>
          <el-row :gutter="10">
            <el-col :span="4" :class="'field-name'">
              单位信息
            </el-col>
            <el-col :span="20">
              {{ company.company }}
            </el-col>
          </el-row>
          <el-row :gutter="10">
            <el-col :span="4" :class="'field-name'">
              纳税人识别号
            </el-col>
            <el-col :span="20">
              {{ company.taxNo }}
            </el-col>
          </el-row>
        </div>
      </div>
<!--      <div class="akz-pan">
        <div class="akz-pan-title">
          开票信息
        </div>
        <div class="akz-pan-content">
          <el-row :gutter="10">
            <el-col :span="4" :class="'field-name'">
              发票类型
            </el-col>
            <el-col :span="20">
              <el-button link class="akz-link">变更</el-button>
            </el-col>
          </el-row>
          <el-row :gutter="10">
            <el-col :span="4" :class="'field-name'">
              电子邮箱
            </el-col>
            <el-col :span="20">
              <el-button link class="akz-link">换绑</el-button>
            </el-col>
          </el-row>
          <el-row :gutter="10">
            <el-col :span="4" :class="'field-name'">
              开票状态
            </el-col>
            <el-col :span="20">
            </el-col>
          </el-row>
          <el-row :gutter="10">
            <el-col :span="24">
              <el-button type="default" style="margin-left: 0">开票确认</el-button>
              <el-button type="default" link style="margin-left: 0">取消确认</el-button>
              <span style="vertical-align: bottom;font-size: 12px;color: #A4A4A4;margin-left: 20px;line-height: 32px">确认开票后，开票状态变为「已开具」</span>
            </el-col>
          </el-row>
        </div>
      </div>-->
      <div class="akz-pan">
        <div class="akz-pan-title">
          寄送信息
          <el-button link class="akz-link" @click="openPostInfoUpdate">编辑</el-button>
        </div>
        <div class="akz-pan-content">
          <el-row :gutter="10">
            <el-col :span="4" :class="'field-name'">
              是否自提
            </el-col>
            <el-col :span="20">
              {{ postStatus }}
            </el-col>
          </el-row>
          <el-row :gutter="10" v-if="postStatus==='否'">
            <el-col :span="4" :class="'field-name'">
              收件人
            </el-col>
            <el-col :span="20">
              {{ post.contactsName }}
            </el-col>
          </el-row>
          <el-row :gutter="10" v-if="postStatus==='否'">
            <el-col :span="4" :class="'field-name'">
              收件人电话
            </el-col>
            <el-col :span="20">
              {{ post.contactsPhone }}
            </el-col>
          </el-row>
          <el-row :gutter="10" v-if="postStatus==='否'">
            <el-col :span="4" :class="'field-name'">
              收件地址
            </el-col>
            <el-col :span="20">
              {{ post.address }}
            </el-col>
          </el-row>
          <el-row :gutter="10" v-if="postStatus==='否'">
            <el-col :span="4" :class="'field-name'">
              详细地址
            </el-col>
            <el-col :span="20">
              {{ post.roomNo }}
            </el-col>
          </el-row>
        </div>
      </div>
      <div class="akz-pan">
        <div class="akz-pan-title">
          流程记录
        </div>
        <div class="akz-pan-content">
          <el-table style="width: 100%" :data="orderLogRender">
            <el-table-column prop="operatorName" label="处理人" align="left" header-align="left" width="200">
              <template #default="scope">
                <span v-if="scope.row.__operatorType!==''">{{ scope.row.__operatorType }} <span v-if="scope.row.operatorType!=='CUSTOMER'">/</span></span>
                <span v-if="scope.row.operatorType!=='CUSTOMER'">{{ scope.row.operatorName }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="__createTime" label="处理时间" align="left" header-align="left" width="180">
            </el-table-column>
            <el-table-column prop="content" label="内容" align="left" header-align="left">
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
  </el-drawer>
  <PopupForm :show-popup="updateDescription.show" pop-title="编辑备注" :confirm-action="doUpdateOrderDescription"
             @close-popup="closeDescriptionUpdate" :form-config="updateDescription.config"
             ref="descriptionUpdatePop"></PopupForm>
  <PopupForm :show-popup="refuseDialog.show" pop-title="退回订单" :confirm-action="doRefuseOrder"
             @close-popup="closeRefuseOrderDialog" :form-config="refuseDialog.config"
             ref="refuseOrderDialogRef"></PopupForm>
  <TrainClassSelect ref="detailTrainClassSelectRef" :confirm-action="doUpdateTrainClass"></TrainClassSelect>

  <PopupForm :show-popup="updatePersonInfo.show" pop-title="编辑个人信息" :confirm-action="doUpdatePersonInfo"
             @close-popup="closePersonInfoUpdate" :form-config="updatePersonInfo.config"
             ref="updatePersonInfoRef"></PopupForm>
  <PopupForm :show-popup="updateCompanyInfo.show" pop-title="编辑公司信息" :confirm-action="doUpdateCompanyInfo"
             @close-popup="closeCompanyInfoUpdate" :form-config="updateCompanyInfo.config" :label-width="'120px'"
             ref="updateCompanyInfoRef"></PopupForm>
  <PopupForm :show-popup="updatePostInfo.show" pop-title="编辑邮寄信息" :confirm-action="doUpdatePostInfo"
             @close-popup="closePostInfoUpdate" :form-config="updatePostInfo.config"
             ref="updatePostInfoRef"></PopupForm>

</template>

<script>

import SupplierSignApi from "@/api/SupplierSignApi";
import DateFormat from "@/components/util/DateFormat";
import Storage from "@/components/util/Storage";
import SupplierSaleOrderApi from "@/api/SupplierSaleOrderApi";
import OrderLogApi from "@/api/OrderLogApi";
import TrainClassSelect from "@/components/trainClass/TrainClassSelect";
import {ref} from "vue";
import TrainClassMemberApi from "@/api/TrainClassMemberApi";
import {ElMessage as Message} from 'element-plus'

export default {
  name: "SignMngDetail",
  mixins: [SupplierSignApi, DateFormat, Storage, SupplierSaleOrderApi,OrderLogApi,TrainClassMemberApi ],
  components:{ TrainClassSelect },
  setup() {
    const descriptionUpdatePop = ref(null)
    const detailTrainClassSelectRef = ref(null)
    const updatePersonInfoRef = ref(null)
    const updateCompanyInfoRef = ref(null)
    const updatePostInfoRef = ref(null)
    return {
      descriptionUpdatePop,detailTrainClassSelectRef,updatePersonInfoRef, updateCompanyInfoRef
      , updatePostInfoRef
    }
  },
  emits:["refreshList"],
  data() {
    return {
      show: false,
      orderNo: "",
      orderRefused : false,
      order: {},
      person: {},
      company: {},
      post: {},
      billOut: {},
      orderLog:[],
      loading: true,
      supplierId: -1,
      updateDescription: {
        show: ref(false),
        id: -1,
        config: [
          {
            type: 'area',
            title: '备注',
            placeHolder: '(非必填)',
            bindField: 'comment',
            rowCount: 9,
            rules: [{
              required: false,
              message: '长度小于1000字',
              trigger: 'blur',
              pattern: /^.{0,1000}$/
            }]
          }
        ]
      },
      updatePersonInfo: {
        show: ref(false),
        config: [
          {
            type: 'input',
            title: '姓名',
            bindField: 'customerName',
            rules: [{required: true, message: '请输入姓名', trigger: 'blur'}]
          }, {
            type: 'input',
            title: '联系电话',
            bindField: 'phone',
            rules: [{required: true, message: '请输入联系', trigger: 'blur'}]
          }, {
            type: 'input',
            title: '身份证号',
            bindField: 'idCardNo',
            rules: [{required: true, message: '请输入身份证号', trigger: 'blur'}]
          }
        ]
      },
      updateCompanyInfo: {
        show: ref(false),
        config: [
          {
            type: 'input',
            title: '单位名称',
            bindField: 'company',
            rules: [{required: true, message: '请输入单位名称', trigger: 'blur'}]
          }, {
            type: 'input',
            title: '纳税人识别号',
            bindField: 'taxNo',
            rules: [{required: true, message: '请输入纳税人识别号', trigger: 'blur'}]
          }
        ]
      },
      updatePostInfo: {
        show: ref(false),
        config: [
          {
            type: 'switch',
            title: '是否自提',
            bindField: 'selfPick',
            trueText: '是',
            falseText: '否',
            rules: [{required: true, message: '请选择是否自提', trigger: 'blur'}]
          }, {
            type: 'input',
            title: '收件人',
            bindField: 'contactsName',
            placeHolder: '请输入收件人姓名(非必填)',
            rules: [{required: false, message: '请输入收件人姓名', trigger: 'blur'}]
          }, {
            type: 'input',
            title: '收件人电话',
            bindField: 'contactsPhone',
            placeHolder: '请输入收件人电话(非必填)',
            rules: [{required: false, message: '请输入收件人电话', trigger: 'blur'}]
          }, {
            type: 'input',
            title: '收件地址',
            bindField: 'address',
            placeHolder: '请输入收件地址(非必填)',
            rules: [{required: false, message: '请输入收件地址', trigger: 'blur'}]
          }, {
            type: 'input',
            title: '详细地址',
            bindField: 'roomNo',
            placeHolder: '请输入详细地址(非必填)',
            rules: [{required: false, message: '请输入详细地址', trigger: 'blur'}]
          }
        ]
      },
      refuseDialog: {
        show: ref(false),
        id: -1,
        config: [
          {
            type: 'area',
            title: '退回原因',
            placeHolder: '填写退回原因',
            bindField: 'comment',
            rowCount: 9,
            rules: [{
              required: true,
              message: '长度小于500字',
              trigger: 'blur',
              pattern: /^.{1,500}$/
            }]
          }
        ]
      },
      idCardFrontTimeStamp:'',
      idCardBackTimeStamp:'',
      idPhotoTimeStamp: ''
    }
  },
  methods: {
    getDetail() {
      const supplierId = this.getItem("_supplierId")
      if (supplierId && supplierId.length < 1) {
        return
      }
      this.$data.supplierId = supplierId

      this.apiOrderDetail(this.$data.orderNo).then((response) => {
        let {order, person, company, post, billOut} = response.data
        this.$data.order = order
        this.$data.person = person
        this.$data.company = company
        this.$data.post = post
        this.$data.billOut = billOut
        this.$data.loading = false

        if(order.orderStatus === 'REFUSE'){
          this.$data.orderRefused = true
        }

      })
      this.apiOrderLog(this.$data.orderNo).then((response)=>{
        this.$data.orderLog = response.data
      })
    },
    openDetail(orderNo) {
      this.$data.orderNo = orderNo
      this.getDetail()
      this.$data.show = true
    },
    closeDetail() {
      this.$data.loading = true
      this.$data.order = {}
      this.$data.person = {}
      this.$data.company = {}
      this.$data.post = {}
      this.$data.billOut = {}
      this.$data.orderLog = []
      this.$data.orderRefused = false
    },
    /* 备注 */
    doUpdateOrderDescription(data) {
      this.apiUpdateSignComment(this.$data.orderNo, data.comment).then(() => {
        this.$data.order.signUpComment = data.comment
        this.closeDescriptionUpdate()
        this.$emit("refreshList")
      })
    },
    openUpdateDescription() {
      this.$data.updateDescription.show = true
      this.descriptionUpdatePop.initialData({comment: this.$data.order.signUpComment})
    },
    closeDescriptionUpdate(){
      this.$data.updateDescription.show = false
    },
    cardFrontUpdateSuccess(){
      this.$data.idCardFrontTimeStamp = new Date().getTime()
    },
    cardBackUpdateSuccess(){
      this.$data.idCardBackTimeStamp = new Date().getTime()
    },
    idPhotoUpdateSuccess(){
      this.$data.idPhotoTimeStamp = new Date().getTime()
    },
    /* 退回订单 */
    openRefuseDialog(sSaleOrderNo){
      this.$data.refuseDialog.orderNo = sSaleOrderNo
      this.$data.refuseDialog.show = true
    },
    doRefuseOrder(data){
      this.apiReturnOrder([this.$data.refuseDialog.orderNo],data.comment).then(()=>{
        this.$data.show = false
      })
    },
    closeRefuseOrderDialog(){
      this.$data.refuseDialog.show = false
    },
    /* 确认订单 */
    confirmOrder(){
      const _this = this
      this.apiConfirmOrder([this.$data.orderNo]).then(()=>{
        this.$emit("refreshList")
        _this.getDetail()
      })
    },
    openClassChoose(){
      this.detailTrainClassSelectRef.setVisible(true)
      this.detailTrainClassSelectRef.setCandidateIds([this.$data.orderNo])
    },
    /* 选择班级 */
    doUpdateTrainClass(orderNos,classId){
      this.apiAddMemberClass(orderNos,classId).then(()=>{
        this.loadMembers()
        this.search()
      },(error)=>{
        Message.error(error.message)
      })
    },
    /* 更新个人信息 */
    doUpdatePersonInfo(params) {
      const requestParam = {
        idCardNo: params.idCardNo,
        trueName: params.customerName,
        phone: params.phone
      }
      this.apiUpdatePersonInfo(this.$data.orderNo, requestParam).then(() => {
        this.$data.person = params
        this.closePersonInfoUpdate()
        this.$emit("refreshList")
      })
    },
    openPersonInfoUpdate() {
      this.$data.updatePersonInfo.show = true
      this.updatePersonInfoRef.initialData(this.$data.person)
    },
    closePersonInfoUpdate() {
      this.$data.updatePersonInfo.show = false
    },
    /* 更新单位信息 */
    doUpdateCompanyInfo(params) {
      this.apiUpdateCompanyInfo(this.$data.orderNo, params).then(() => {
        this.$data.company = params
        this.closeCompanyInfoUpdate()
        this.$emit("refreshList")
      })
    },
    openCompanyInfoUpdate() {
      this.$data.updateCompanyInfo.show = true
      this.updateCompanyInfoRef.initialData(this.$data.company)
    },
    closeCompanyInfoUpdate() {
      this.$data.updateCompanyInfo.show = false
    },
    /* 更新邮寄信息 */
    doUpdatePostInfo(params) {
      this.apiUpdatePostInfo(this.$data.orderNo, params).then(() => {
        this.$data.post = params
        this.closePostInfoUpdate()
        this.$emit("refreshList")
      })
    },
    openPostInfoUpdate() {
      this.$data.updatePostInfo.show = true
      this.updatePostInfoRef.initialData(this.$data.post)
    },
    closePostInfoUpdate() {
      this.$data.updatePostInfo.show = false
    },
  },
  mounted() {

  },
  computed: {
    postStatus() {
      if (this.$data.post.selfPick !== null) {
        return this.$data.post.selfPick === true ? "是" : "否"
      } else {
        return "未知"
      }
    },
    dealTime() {
      return this.dateFormat(this.$data.order.dealTime)
    },
    idCardFront() {
      return "/api/shopping/supplier/sign-up-order-detail/" + this.$data.supplierId + "/" + this.$data.orderNo + "/pic/ID_CARD_FRONT?t="+this.$data.idCardFrontTimeStamp
    },
    idCardBack() {
      return "/api/shopping/supplier/sign-up-order-detail/" + this.$data.supplierId + "/" + this.$data.orderNo + "/pic/ID_CARD_BACK?t="+this.$data.idCardBackTimeStamp
    },
    idPhoto() {
      return "/api/shopping/supplier/sign-up-order-detail/" + this.$data.supplierId + "/" + this.$data.orderNo + "/pic/ID_PHOTO?t"+this.$data.idPhotoTimeStamp
    },
    updateCardFrontPath(){
      return "/api/shopping/supplier/sign-up-order-detail/" + this.$data.supplierId + "/" + this.$data.orderNo + "/pic/ID_CARD_FRONT"
    },
    updateCardBackPath(){
      return "/api/shopping/supplier/sign-up-order-detail/" + this.$data.supplierId + "/" + this.$data.orderNo + "/pic/ID_CARD_BACK"
    },
    updateIDPhotoPath() {
      return "/api/shopping/supplier/sign-up-order-detail/" + this.$data.supplierId + "/" + this.$data.orderNo + "/pic/ID_PHOTO"
    },
    orderLogRender(){
      return this.$data.orderLog.map((row) => {
        let __operatorTypeName
        switch (row.operatorType){
          case "EMPLOYEE": __operatorTypeName = "安考站客服";break;
          case "SUPPLIER": __operatorTypeName = "";break;
          case "CUSTOMER": __operatorTypeName = "学员";break;
          case "SYSTEM": __operatorTypeName = "系统";break;
          default: __operatorTypeName = ""
        }
        row.__operatorType = __operatorTypeName
        row.__createTime = this.dateFormat(row.createTime)
        return row
      })
    },
  }
}
</script>

<style scoped>
.id-card-img {
  display: inline-block;
  margin-right: 20px;
  cursor: pointer;
}

.id-card-img .el-image {
  width: 250px;
  height: 170px;
}

.id-card-img.id-photo .el-image {
  height: 175px;
  width: 125px;
}

.id-card-img .demonstration {
  color: var(--el-text-color-secondary);
  font-size: 14px;
  margin-bottom: 20px;
}

.id-card-img .image-slot {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: var(--el-fill-color-light);
  color: var(--el-text-color-secondary);
  font-size: 22px;
}

.id-card-img .image-slot .el-icon {
  font-size: 22px;
}

.id-card-img .id-card-img-des {
  font-size: 18px;
}

.id-card-img .id-card-name {
  text-align: center;
  color: #919399;
  margin-bottom: 5px;
}

.id-card-img .el-upload__tip {
  color: #d6d6d6;
}
.order-refused{
  font-size: 12px;
  color: white;
  background-color: #F56C6C !important;
  padding:15px;

}

</style>