<template>
  <ConditionFilter :filterConfig="filterConfig" @doQuery="search" :show-date-ranger="true" :do-query-when-start="true"
                   :close-after-query="true" ref="conditionFilter"></ConditionFilter>
  <div v-loading="loading">
    <ListHeaderTabs :default-tab-id="1" :configs="tabsConfig" @tab-change="search" ref="tabFilter"></ListHeaderTabs>
    <div class="table">
      <el-table
          :data="listData"
          style="width: 100%"
          @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="50" align="center">
        </el-table-column>
        <el-table-column prop="orderNo" label="订单号" align="left" header-align="left" width="200">
          <template #default="scope">
            {{ scope.row.orderNo }}
            <span v-if="currentTab===''" >
              <br />
              <span v-if="scope.row.signStatus==='SIGNED' && scope.row.orderStatus==='ENABLE'" class="akz-tag success">已报名</span>
              <span v-if="scope.row.signStatus==='UN_SIGN' && scope.row.orderStatus==='ENABLE'" class="akz-tag warning">待报名</span>
              <span v-if="scope.row.orderStatus==='REFUSE'" class="akz-tag danger">退单</span>
            </span>
          </template>
        </el-table-column>
        <el-table-column prop="subjectType" label="报名类型\科目" align="left" header-align="left" width="220">
          <template #default="scope">
            {{ scope.row.categoryName }}\<br />{{ scope.row.productName }}
          </template>
        </el-table-column>
        <el-table-column prop="studentName" label="姓名" align="center" width="60">
          <template #default="scope">
            {{ scope.row.studentName }}
          </template>
        </el-table-column>
        <el-table-column v-if="currentTab==='SIGNED'||currentTab===''" prop="className" label="班级" width="120" align="left" header-align="left" >
        </el-table-column>
        <el-table-column prop="__comment" label="备注" align="left" header-align="left" >
        </el-table-column>
        <el-table-column prop="__createTime" label="创建时间" align="center" width="160">
        </el-table-column>
        <el-table-column label="操作" width="200" align="left">
          <template #default="scope">
            <el-button link class="akz-link" @click="edit(scope.row.orderNo)">详情</el-button>
            <el-button v-if="scope.row.orderStatus==='ENABLE'" link class="akz-link" @click="download(scope.row.orderNo)">材料下载</el-button>
            <el-popconfirm v-if="scope.row.orderStatus==='ENABLE' && scope.row.signStatus==='UN_SIGN'"  confirm-button-text="确定"
                           cancel-button-text="取消"
                           title="是否确认?"
                           @confirm="updateStatus(scope.row.id,'ENABLE')">
              <template #reference>
                <el-button link class="akz-link">确认</el-button>
              </template>
            </el-popconfirm>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="table-multi-btns">
      <span>批量操作：</span>
      <el-button link class="akz-link" @click="doDownloadBatch">下载</el-button>
    </div>
    <div class="pagination-item">
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="record.currentIndex"
          :page-sizes="[20, 50, 100]"
          :page-size="record.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="record.total"></el-pagination>
    </div>
  </div>
  <SignMngDetail ref="signMngDetail" @refresh-list="search" />
  <TrainClassSelect ref="trainClassSelect" :confirm-action="doUpdateTrainClass"></TrainClassSelect>
</template>

<script>
import ListHeaderTabs from "@/components/frame/tabs/ListHeaderTabs";
import SupplierSignApi from "@/api/SupplierSignApi";
import {ref} from "vue";
import dateFormat from "@/components/util/DateFormat";
import SignMngDetail from "@/components/orderMng/signMng/SignMngDetail";
import TrainClassSelect from "@/components/trainClass/TrainClassSelect";
import { ElMessage as Message } from "element-plus"

export default {
  name: "SignMng",
  components: {ListHeaderTabs,SignMngDetail,TrainClassSelect },
  emits: ["goto-module"],
  mixins:[dateFormat,SupplierSignApi],
  setup() {
    const conditionFilter = ref(null)
    const tabFilter = ref(null)
    const signMngDetail = ref(null)
    const trainClassSelect = ref(null)
    return {
      conditionFilter,tabFilter,signMngDetail,trainClassSelect
    }
  },
  data() {
    return {
      loading:false,
      currentTab:"UN_SIGN",
      record: {
        list: [],
        currentIndex: 1,
        total: 100,
        pageSize: 20,
        selected: [],
        statistic:{
          startTime:'2000-01-01 00:00:00',
          endTime:'2000-01-01 00:00:00',
          orderCount: 0,
          preAmount: 0,
          amount: 0
        }
      },
      filterConfig: [
        {
          type: 'input',
          title: '姓名',
          placeHolder: '请输入...',
          bindField: 'studentName',
          showInMain: true
        },
        {
          type: 'select-remote',
          title: '报名类型',
          placeHolder: '请选择',
          source: this.apiListForType,
          sourceParams:{
            supplierId: 1
          },
          bindField: 'productNo',
          textField: 'productName',
          valueField: 'productNo',
          showInMain: true
        },
        {
          type: 'input',
          title: '订单号',
          placeHolder: '请输入完整订单号...',
          bindField: 'sOrderNo'
        },{
          type: 'input',
          title: '备注',
          placeHolder: '请输入备注关键字...',
          bindField: 'comment'
        }
      ],
      tabsConfig: [
        {id: 1, tabName: "待报名",value : "UN_SIGN"},
        {id: 2, tabName: "已报名",value : "SIGNED"},
        {id: 4, tabName: "全部",value : ""}
      ]
    }
  },
  methods: {
    doQuery(filters) {
      //获取分页信息
      const _currentIndex = this.$data.record.currentIndex
      const _pageSize = this.$data.record.pageSize
      this.$data.loading = true

      //获取页大小和页数
      this.apiList(filters, _pageSize, _currentIndex).then((response) => {
        this.$data.loading = false
        this.$data.record.total = response.data.total
        this.$data.record.list = response.data.data ? response.data.data : []
      })
    },
    search() {
      const filters = this.conditionFilter.getFilters()
      const tabIndex = this.tabFilter.getFilters()
      let tabFilterValue
      this.$data.tabsConfig.forEach(x=>{
        if(x.id === tabIndex){
          tabFilterValue = x.value
        }
      })
      if(tabFilterValue !== ""){
        if(tabFilterValue === "REFUSED"){
          filters["orderStatus"] = 'REFUSE'
        }else{
          filters["status"] = tabFilterValue
          filters["orderStatus"] = 'ENABLE'
        }
      }
      this.$data.currentTab = tabFilterValue

      this.doQuery(filters)
    },
    handleSizeChange(pageSize) {
      this.record.pageSize = pageSize
      this.search()
    },
    handleSelectionChange(changed) {
      this.$data.record.selected = changed.map(x => x.orderNo)
    },
    handleCurrentChange(pageIndex) {
      this.record.currentIndex = pageIndex
      this.search()
    },
    edit(id) {
      this.signMngDetail.openDetail(id)
    },
    /* 选择班级 */
    openClassChoose(orderNos){
      this.trainClassSelect.setVisible(true)
      this.trainClassSelect.setCandidateIds([orderNos])
    },
    doUpdateTrainClass(orderNos,classId){
      this.apiUpdateMemberClass(orderNos,classId).then(()=>{
        this.loadMembers()
        this.search()
      })
    },
    /* 下载订单 */
    download(sOrderNo){
      this._downloadSignContent([sOrderNo])
    },
    doDownloadBatch(){
      if(this.$data.record.selected && this.$data.record.selected.length === 0){
        Message.warning("选择要下载的订单")
        return
      }
      if(this.$data.record.selected.length > 20){
        Message.warning("下载材料订单不能超过20个")
        return
      }
      this._downloadSignContent(this.$data.record.selected)
    },
    _downloadSignContent(orderNos){
      this.apiDownloadOrders(orderNos).then(response => {
        const contentDisposition = response.headers["content-disposition"]
        let fileName = "文件.zip";
        if(contentDisposition){
          const index = contentDisposition.indexOf("filename=");
          fileName = contentDisposition.substring(index+9)
        }
        const res = response.data

        const link = document.createElement('a');  // 创建元素
        let blob = new Blob([res], { type: 'application/x-download' });
        link.style.display = 'none';
        link.href = URL.createObjectURL(blob);   // 创建下载的链接
        link.setAttribute('download', fileName);  // 给下载后的文件命名
        document.body.appendChild(link);
        link.click();  // 点击下载
        document.body.removeChild(link);  //  下载完成移除元素
        window.URL.revokeObjectURL(link.href);  // 释放掉blob对象
      }).catch(err => {
        Message.error(err.message)
      })
    },
  },
  computed: {
    listData: function () {
      return this.$data.record.list.map((row) => {
        //处理备注
        if(row.comment && row.comment.length>70){
          row.__comment = row.comment.substring(0,70)+"..."
        }else {
          row.__comment = row.comment
        }

        //处理时间显示
        row.__createTime = this.dateFormat(row.signTime)
        return row
      })
    }
  }
}
</script>

<style>
.pagination-item {
  background-color: white;
  padding: 12px;
}

.el-table {
  font-size: 12px !important;
}
</style>