<template>
  <ConditionFilter :filterConfig="filterConfig" @doQuery="search" :show-date-ranger="true" :do-query-when-start="true"
                   :close-after-query="true" ref="conditionFilter"></ConditionFilter>
  <div v-loading="loading">
    <ListHeaderTabs :default-tab-id="1" :configs="tabsConfig" @tab-change="search" ref="tabFilter"></ListHeaderTabs>
    <div class="table">
      <el-table
          :data="listData"
          style="width: 100%"
          @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="50" align="center">
        </el-table-column>
        <el-table-column prop="orderNo" label="订单号" align="left" header-align="left" width="200">
          <template #default="scope">
            {{ scope.row.orderNo }}
            <span v-if="currentTab===''" >
              <br />
              <span v-if="scope.row.billOutStatus==='AWAIT'" class="akz-tag warning">待开票</span>
              <span v-if="scope.row.billOutStatus==='DONE'" class="akz-tag success">已开票</span>
              <span v-if="scope.row.billOutStatus==='IGNORE'" class="akz-tag info">已忽略</span>
            </span>
          </template>
        </el-table-column>
        <el-table-column prop="subjectType" label="报名类型\科目" align="left" header-align="left" width="220">
          <template #default="scope">
            {{ scope.row.categoryName }}\<br />{{ scope.row.productName }}
          </template>
        </el-table-column>
        <el-table-column prop="customerName" label="姓名" align="center" width="60">
          <template #default="scope">
            {{ scope.row.customerName }}
          </template>
        </el-table-column>
        <el-table-column prop="__amounts" label="订单金额\开票公司\税号" align="left" header-align="left">
          <template #default="scope">
            {{ scope.row.__amounts }} \<br />{{ scope.row.company }} \<br />{{ scope.row.taxNo }}
          </template>
        </el-table-column>
        <el-table-column prop="__createTime" label="创建时间" align="center" width="160">
        </el-table-column>
        <el-table-column label="操作" width="200" align="left">
          <template #default="scope">
            <el-button link class="akz-link" @click="edit(scope.row.orderNo)">详情</el-button>
            <el-button link class="akz-link" v-if="scope.row.billOutStatus!=='DONE'" @click="updateStatusSingle(scope.row.orderNo,'DONE')">开票</el-button>
            <el-button link class="akz-link" v-if="scope.row.billOutStatus!=='AWAIT'" @click="updateStatusSingle(scope.row.orderNo,'AWAIT')">取消开票</el-button>
            <el-button link class="akz-link" v-if="scope.row.billOutStatus!=='IGNORE'" @click="updateStatusSingle(scope.row.orderNo,'IGNORE')">忽略</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="table-multi-btns">
      <span>批量操作：</span>
      <el-button link class="akz-link" v-if="currentTab!=='DONE'" @click="changeStatusBatch('DONE')">开票</el-button>
      <el-button link class="akz-link" v-if="currentTab!=='AWAIT'" @click="changeStatusBatch('AWAIT')">取消开票</el-button>
      <el-button link class="akz-link" v-if="currentTab!=='IGNORE'" @click="changeStatusBatch('IGNORE')">忽略</el-button>
    </div>
    <div class="pagination-item">
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="record.currentIndex"
          :page-sizes="[20, 50, 100]"
          :page-size="record.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="record.total"></el-pagination>
    </div>
  </div>
  <SignMngDetail ref="signMngDetail" @refresh-list="search" />
</template>

<script>
import ListHeaderTabs from "@/components/frame/tabs/ListHeaderTabs";
import {ref} from "vue";
import SupplierSignApi from "@/api/SupplierSignApi";
import SupplierSaleOrderApi from "@/api/SupplierSaleOrderApi";
import dateFormat from "@/components/util/DateFormat";
import NumFormat from "@/components/util/NumFormat";
import SignMngDetail from "@/components/orderMng/signMng/SignMngDetail";
import {ElMessage as Message} from "element-plus"

export default {
  name: "BillOutMng",
  components: {ListHeaderTabs,SignMngDetail },
  mixins:[ SupplierSignApi,SupplierSaleOrderApi,dateFormat,NumFormat ],
  emits: ["goto-module"],
  setup() {
    const conditionFilter = ref(null)
    const tabFilter = ref(null)
    const signMngDetail = ref(null)
    return {
      conditionFilter,tabFilter,signMngDetail
    }
  },
  data(){
    return {
      loading:false,
      currentTab:"AWAIT",
      record: {
        list: [],
        currentIndex: 1,
        total: 100,
        pageSize: 20,
        selected: [],
        statistic:{
          startTime:'2000-01-01 00:00:00',
          endTime:'2000-01-01 00:00:00',
          orderCount: 0,
          preAmount: 0,
          amount: 0
        }
      },
      filterConfig: [
        {
          type: 'input',
          title: '订单号',
          placeHolder: '请输入完整订单号...',
          bindField: 'orderNo',
          showInMain: true
        },{
          type: 'select-remote',
          title: '报名类型',
          placeHolder: '请选择',
          source: this.apiListForType,
          sourceParams:{
            supplierId: 1
          },
          bindField: 'productNo',
          textField: 'productName',
          valueField: 'productNo',
          showInMain: true
        }
      ],
      tabsConfig: [
        {id: 1, tabName: "待开票",value : "AWAIT"},
        {id: 2, tabName: "已开票",value : "DONE"},
        {id: 3, tabName: "已忽略",value : "IGNORE"},
        {id: 4, tabName: "全部",value : ""}
      ]
    }
  },
  methods:{
    doQuery(filters) {
      //获取分页信息
      const _currentIndex = this.$data.record.currentIndex
      const _pageSize = this.$data.record.pageSize
      this.$data.loading = true

      //获取页大小和页数
      this.apiSupplierGetBillOutList(filters, _pageSize, _currentIndex).then((response) => {
        this.$data.loading = false
        this.$data.record.total = response.data.total
        this.$data.record.list = response.data.data ? response.data.data : []
      })
    },
    search() {
      const filters = this.conditionFilter.getFilters()
      const tabIndex = this.tabFilter.getFilters()
      let tabFilterValue
      this.$data.tabsConfig.forEach(x=>{
        if(x.id === tabIndex){
          tabFilterValue = x.value
        }
      })
      this.$data.currentTab = tabFilterValue
      if(tabFilterValue !== ""){
        filters["status"] = tabFilterValue
      }
      this.doQuery(filters)
    },
    handleSizeChange(pageSize) {
      this.record.pageSize = pageSize
      this.search()
    },
    handleSelectionChange(changed) {
      this.$data.record.selected = changed.map(x => x.orderNo)
    },
    handleCurrentChange(pageIndex) {
      this.record.currentIndex = pageIndex
      this.search()
    },
    changeStatusBatch(targetStatus){
      const orderNos = this.$data.record.selected
      if(orderNos.length === 0){
        Message.error("选择要操作的数据")
        return;
      }
      this.updateStatus(orderNos,targetStatus)
    },
    updateStatus(orderNos,targetStatus){
      this.apiSupplierUpdateBillOutStatus(orderNos,targetStatus).then(()=>{
        this.search()
      },(error)=>{
        Message.error(error.message)
      })
    },
    updateStatusSingle(orderNo,targetStatus){
      this.updateStatus([orderNo],targetStatus)
    },
    edit(id) {
      this.signMngDetail.openDetail(id)
    },

  },
  computed: {
    listData: function () {
      return this.$data.record.list.map((row) => {
        //处理备注
        if(row.comment && row.comment.length>70){
          row.__comment = row.comment.substring(0,70)+"..."
        }else {
          row.__comment = row.comment
        }
        row.__amounts = this.currency(row.amounts/100)
        //处理时间显示
        row.__createTime = this.dateFormat(row.createTime)
        return row
      })
    }
  }
}
</script>