import request from "@/components/util/http";

export default {
    methods:{
        apiHomeStatistic(){
            return request({
                url : '/api/supplier/supplier/statistic/common',
                method: 'get',
            })
        },
        apiHomeOrderCountsIn48Hours(){
            return request({
                url : '/api/supplier/supplier/statistic/recent-order-history',
                method: 'get',
            })
        }
    }
}