import request from "@/components/util/http";
import Storage from "@/components/util/Storage";

export default {
    mixins:[ Storage ],
    methods:{
        apiOrderLog(sSaleOrderNo){
            const supplierId = this.getItem("_supplierId")
            if(supplierId && supplierId.length < 1){
                return
            }
            return request({
                url : '/api/shopping/supplier/order-log/'+supplierId+"/"+sSaleOrderNo,
                method: 'get',
            })
        }
    }
}