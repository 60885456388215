const NumFormat = {
    methods: {
        thousandSplit(value) {
            if (!value) return '0';
            const intPart = Number(value) | 0; //获取整数部分
            return intPart.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,'); //将整数部分逢三一断
        },
        currency(value,showSymbol){
            let options
            let local
            if(showSymbol === true){
                options = {
                    style: 'currency',
                    currency: 'CNY',
                }
            }else{
                options = {
                    'minimumFractionDigits':2,
                    'maximumFractionDigits':2
                }
                local = 'zh-CN'
            }
            return value.toLocaleString(local, options);
        }
    }
}
export default NumFormat