<template>
  <ConditionFilter ref="conditionFilter" :filterConfig="filterConfig" @doQuery="doQuery" :show-date-ranger="true"
                   :date-range-default="'thisWeek'"
                   :do-query-when-start="true"
                   :close-after-query="true"></ConditionFilter>
  <div v-loading="loading">
    <div class="table">
      <el-table
          :data="listData"
          style="width: 100%">
        <el-table-column prop="featureName" width="200" label="功能模块" align="center">
        </el-table-column>
        <el-table-column prop="supplierUserNickName" width="150" label="操作人姓名" align="center">
          <template #default="scope">
            {{ scope.row.nickName }}
          </template>
        </el-table-column>
        <el-table-column prop="__createTime" label="操作时间" width="180" align="center">
        </el-table-column>
        <el-table-column prop="content" label="操作内容" align="left" header-align="left">
        </el-table-column>
      </el-table>
    </div>
    <div class="pagination-item">
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="record.currentIndex"
          :page-sizes="[20, 50, 100]"
          :page-size="record.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="record.total"></el-pagination>
    </div>
  </div>
</template>

<script>
import FeatureApi from "@/api/FeatureApi";
import AccountApi from "@/api/AccountApi";
import {ref} from "vue";
import dateFormat from "@/components/util/DateFormat";
import OperationLogApi from "@/api/OperationLogApi";

export default {
  name: "OperationLogView",
  mixins:[FeatureApi,AccountApi,OperationLogApi,dateFormat],
  setup() {
    const conditionFilter = ref(null)
    return {
      conditionFilter
    }
  },
  data(){
    return{
      loading:false,
      record: {
        list: [],
        currentIndex: 1,
        total: 100,
        pageSize: 20,
        selected: []
      },
      filterConfig: [
        {
          type: 'select-group-remote',
          title: '功能',
          placeHolder: '选择功能',
          source: this.apiListAllFeature,
          bindField: 'featureCode',
          textField: 'featureName',
          valueField: 'featureCode',
          subsField: 'subFeatures',
          showInMain: true
        },
        {
          type: 'select-remote',
          title: '操作人',
          placeHolder: '请选择',
          source: this.apiHistory,
          sourceParams:{
          },
          bindField: 'supplierUserId',
          textField: 'nickName',
          valueField: 'id',
          showInMain: true
        }
      ]
    }
  },
  methods:{
    doQuery(filters) {
      //获取分页信息
      const _currentIndex = this.$data.record.currentIndex
      const _pageSize = this.$data.record.pageSize

      this.$data.loading = true
      //获取页大小和页数
      this.apiOperationLogList(filters, _pageSize, _currentIndex).then((response) => {
        this.$data.record.total = response.data.total
        this.$data.record.list = response.data.data ? response.data.data : []
        this.$data.loading = false
      })
    },
    search() {
      const filters = this.conditionFilter.getFilters()
      this.doQuery(filters)
    },
    handleSizeChange(pageSize) {
      this.record.pageSize = pageSize
      this.search()
    },
    handleCurrentChange(pageIndex) {
      this.record.currentIndex = pageIndex
      this.search()
    },
  },
  computed:{
    listData: function () {
      return this.$data.record.list.map((row) => {
        //处理时间显示
        row.__createTime = this.dateFormat(row.createTime)
        return row
      })
    },
  }
}
</script>

<style scoped>

</style>