import request from "@/components/util/http";

export default {
    methods:{
        apiGetOperatorMsgSubscribeConfig(){
            return request({
                url : '/api/supplier/supplier/msg-subscribe/operator',
                method: 'get',
            })
        },
        apiAddOrUpdateMsgSubscribeConfig(msgType,disturbType){
            return request({
                url: '/api/supplier/supplier/msg-subscribe',
                method: 'put',
                data:{
                    subscribeMsgType: msgType,
                    noneWorktimeDisturb: disturbType
                }
            })
        },
        apiRemoveMsgSubscribeConfig(msgType){
            return request({
                url: '/api/supplier/supplier/msg-subscribe/'+msgType,
                method: 'delete'
            })
        }
    }
}