<template>
  <div class="summary-card" :style="{backgroundColor:bgColor}">
    <div class="title">{{ title }}</div>
    <div class="statistic-num">
      <slot></slot>
    </div>
    <div class="op-link" v-if="forwardModule&&forwardModule.length>0">
      <el-button link @click="goToModule(forwardModule)"
                 :style="{color:'white',fontSize:'var(--el-font-size-extra-small)',fontWeight:400}">去处理 &gt;
      </el-button>
    </div>
  </div>
</template>

<script>
export default {
  name: "SummaryCard",
  emits: ['goto-module'],
  props: {
    bgColor: String,
    title: String,
    forwardModule: String
  },
  methods: {
    goToModule(moduleName) {
      this.$emit("goto-module", moduleName)
    }
  }
}
</script>

<style scoped>
.summary-card {
  padding: 18px;
  color: white;
  position: relative;
}

.summary-card > .title {
  font-size: var(--el-font-size-extra-small);
}

.summary-card > .statistic-num {
  font-size: 29px;
}

.summary-card > .op-link {
  position: absolute;
  bottom: 15px;
  right: 15px;
}
</style>