<template>
  <el-dialog v-model="showPopupWindow" title="手机绑定" custom-class="akz-popup"
             @close="closePopup" :destroy-on-close="true">
    <el-form ref="ruleFormRef" :model="formData" :rules="validateRules"  label-position="center" :label-width="labelWidth"
             class="akz-popup-form" status-icon>
      <el-form-item label="手机号" prop="phone" >
        <el-input v-model="formData.phone" placeholder="输入要绑定的手机号码"></el-input>
        <el-button :type="sendSmsCode?'info':'primary'" @click="sendValidateSmsCode" class="akz-btn" style="margin-top:5px;"
                   :disabled="sendSmsCode">{{ sendMsgBtnText }}</el-button>
      </el-form-item>
      <el-form-item label="短信验证码" prop="validateCode" >
        <el-input v-model="formData.validateCode" placeholder="输入发送给手机的短信验证码"></el-input>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="closePopup" link class="akz-btn" style="color: #A4A4A4;font-weight: 400">取消</el-button>
        <el-button type="primary" @click="doConfirm" class="akz-btn" >绑定</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
import {ref} from "vue";
import {ElMessage as Message} from "element-plus"
import AccountApi from "@/api/AccountApi";

export default {
  name: "UserBindPhone",
  setup() {
    const ruleFormRef = ref(null)
    return {
      ruleFormRef
    }
  },
  mixins:[ AccountApi ],
  emits:["close-popup"],
  data(){
    return {
      showPopupWindow: false,
      orderNo:"",
      formData:{
        phone:"",
        validateCode:""
      },
      validateRules:{
        phone:[
          { required: true, message: '请输入正确的手机号', trigger: 'blur',pattern:/^(13[0-9]|14[5-9]|15[0-3,5-9]|16[2,5,6,7]|17[0-8]|18[0-9]|19[0-3,5-9])\d{8}$/ }
        ]
      },
      labelWidth: 100,
      enableConfirm: true,
      sendMsgBtnText: "发送验证码",
      sendSmsCode: false
    }
  },
  methods:{
    openDetail() {
      this.$data.showPopupWindow = true
    },
    closePopup: function () {
      this.$data.showPopupWindow = false
      this.$emit("close-popup")
    },
    doConfirm: function () {
      if(this.$data.formData.validateCode.length < 1){
        Message.error("请输入手机验证码")
        return
      }

      this.ruleFormRef.validate((valid) => {
        if (valid) {
          this.apiDoBindPhone(this.$data.formData.phone,this.$data.formData.validateCode).then(()=>{
            Message.success("手机绑定成功")
            this.closePopup()
          },(error)=>{
            Message.error(error.message)
          })
        } else {
          return
        }
      })
    },
    sendValidateSmsCode(){
      const _this = this
      if(this.$data.sendSmsCode){
        return
      }
      this.$data.sendSmsCode = true
      this.ruleFormRef.validate((valid) => {
        if (valid) {
          this.apiSendPhoneBindSms(this.$data.formData.phone).then(()=>{
            //倒数
            let countDown = 60
            const sendSmsInterval = setInterval(function (){
              _this.$data.sendMsgBtnText = countDown + "秒后再次发送"
              countDown--
              if(countDown <= 0){
                _this.$data.sendSmsCode = false
                _this.$data.sendMsgBtnText = "发送验证码"
                clearInterval(sendSmsInterval)
              }
            },1000)
          },(error)=>{
            this.$data.sendSmsCode = false
            Message.error(error.message)
          })

        } else {
          this.$data.sendSmsCode = false
          Message.error("请输入正确手机号后发送")
          return
        }
      })
    }
  }
}
</script>

<style scoped>

</style>