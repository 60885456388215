import request from "@/components/util/http";
import Storage from "@/components/util/Storage";

export default {
    mixins:[ Storage ],
    methods:{
        apiList(filters,pageSize,pageIndex){
            const supplierId = this.getItem("_supplierId")
            if(supplierId && supplierId.length < 1){
                return
            }
            return request({
                url : '/api/supplier/supplier/train-class/list/' + supplierId + '/' + pageSize + '/' + pageIndex,
                method: 'get',
                params: filters
            })
        },
        apiListSelect(filters){
            const supplierId = this.getItem("_supplierId")
            if(supplierId && supplierId.length < 1){
                return
            }
            return request({
                url : '/api/supplier/supplier/train-class/list-select/' + supplierId,
                method: 'get',
                params: filters
            })
        },
        apiAdd(params){
            const supplierId = this.getItem("_supplierId")
            if(supplierId && supplierId.length < 1){
                return
            }
            params["supplierId"] = supplierId
            return request({
                url : '/api/supplier/supplier/train-class/'+supplierId,
                method: 'post',
                data: params
            })
        },
        /*更新班级名称*/
        apiUpdateClassName(id,className){
            const supplierId = this.getItem("_supplierId")
            if(supplierId && supplierId.length < 1){
                return
            }
            return request({
                url : '/api/supplier/supplier/train-class/'+supplierId+"/"+id+"/class-name",
                method: 'put',
                params:{
                    className: encodeURIComponent(className)
                }
            })
        },
        apiUpdateStatus(id,status){
            const supplierId = this.getItem("_supplierId")
            if(supplierId && supplierId.length < 1){
                return
            }
            return request({
                url : '/api/supplier/supplier/train-class/'+supplierId+"/"+id+"/status/"+status,
                method: 'put'
            })
        },
        apiUpdateDescription(id,description){
            const supplierId = this.getItem("_supplierId")
            if(supplierId && supplierId.length < 1){
                return
            }
            if(!description){
                description = ""
            }
            return request({
                url : '/api/supplier/supplier/train-class/'+supplierId+"/"+id+"/description",
                method: 'put',
                data: {
                    description: description
                }
            })
        },
        apiUpdateStartTime(id,startTime){
            const supplierId = this.getItem("_supplierId")
            if(supplierId && supplierId.length < 1){
                return
            }
            return request({
                url : '/api/supplier/supplier/train-class/'+supplierId+"/"+id+"/start-time",
                method: 'put',
                params:{
                    startTime : this.formatTimestamp(startTime)
                }
            })
        },
        apiUpdateEndTime(id,endTime){
            const supplierId = this.getItem("_supplierId")
            if(supplierId && supplierId.length < 1){
                return
            }
            return request({
                url : '/api/supplier/supplier/train-class/'+supplierId+"/"+id+"/end-time",
                method: 'put',
                params:{
                    endTime : this.formatTimestamp(endTime)
                }
            })
        },
        formatTimestamp(timestamp) {
            let date = new Date(timestamp)
            let Y = date.getFullYear() + '-';
            let M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
            let D = date.getDate()
            return Y + M + D;
        }
    }
}